import React, { Fragment, useEffect, useState } from 'react';
import Routes from '../../routes';
import withAuthentication from '../../auth/session/withAuthentication';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { useIdleTimer } from 'react-idle-timer';
const App = (props) => {
  // console.log('props ==> ', props);
  const [openTab,setOpenTab]= useState(false);
  const handleOnIdle = (event) => {
    props.firebase
      .doSignOut()
      .then((signoutRes) => {
        localStorage.setItem('encryptedString', 'null')
        localStorage.setItem('userId', 'null')
        // localStorage.setItem('isFreshLogin', 'true')
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const checkAuth = () => {
    const userId = localStorage.getItem('userId') || "null"
    return userId !== "null"
  }


  const updateTabCount = () => {
    const count = parseInt(localStorage.getItem("tabCount")) || 0;
    localStorage.setItem("tabCount", count + 1);
  };

  // Decrement tab count when a tab is closed
  const removeTabCount = () => {
    const count = parseInt(localStorage.getItem("tabCount")) || 1;
    localStorage.setItem("tabCount", count - 1);
  };

  // logout on tab close
  useEffect(() => {
    const handleVisibilityChange = () => {
      console.log('visibility change detected');
      if (document.visibilityState === "hidden" ) {
        // Store the timestamp when the tab becomes hidden
        sessionStorage.setItem("lastUnloadTime", Date.now().toString());
      }
    };

    const handlePageLoad = () => {

        // Set a flag in sessionStorage to indicate a reload is happening
        const lastUnloadTime = sessionStorage.getItem("lastUnloadTime");
        if (lastUnloadTime) {
          const timeDifference = Date.now() - parseInt(lastUnloadTime, 10);
          // Treat as a reload if time difference is within the threshold
          if (timeDifference >1000) {
          } else {
            if (checkAuth()) {
              autoLogout();
            }
          }
        }
        else {
          setTimeout(()=>{
            const tabCount = localStorage.getItem("tabCount")
            if(Number(tabCount)<2) {
              if (window.location.pathname !== "/forgotPassword/" && checkAuth()) {
                autoLogout();
              }
            }
          },100)


        }

        // Clear the unload time to avoid stale data
        sessionStorage.removeItem("lastUnloadTime");
      };

      // Check the unload time on page load
      handlePageLoad();

      // Listen to visibility change events
      document.addEventListener("visibilitychange", handleVisibilityChange);

      updateTabCount();

      // Listen for storage changes in other tabs
      window.addEventListener("storage", (event) => {
        if (event.key === "tabCount") {
          if(event.newValue)

          console.log(`Tabs open: ${event.newValue}`);
        }
      });

      // Handle tab close
      window.addEventListener("beforeunload", removeTabCount);


      // Cleanup listener on component unmount
      return () => {
        document.removeEventListener("visibilitychange", handleVisibilityChange);
      };

  }, []);



  const autoLogout=()=>{
    console.log('auto tab close called')
    props.firebase

    .doSignOut()

    .then((signoutRes) => {

      localStorage.setItem('encryptedString', 'null')

      localStorage.setItem('userId', 'null')
      window.location.href='login'
      // localStorage.setItem('isFreshLogin', 'true')

      return;

    })
  }
  const handleOnActive = (event) => {
    // console.log('user is active', event);
    // console.log('time remaining', getRemainingTime());
  };

  const handleOnAction = (event) => {
    // console.log('user did something', event);
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 4 * 60 * 60 * 1000,
    // timeout: 3 * 60 * 1000,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });
  return (
    <Fragment>
      <ReactNotification />
      <Routes firebase={props.firebase} />
    </Fragment>
  );
};
export default withAuthentication(App);
