import React, { useState, useEffect } from 'react';
import DynamicModal from '../../DynamicModal';
import { Row, Container, Image, Button, Col, Form, InputGroup } from 'react-bootstrap';
import s from './style.scss';
import { Formik } from 'formik';
import { EDIT_DETAILS_SCHEMA } from '../../../config/FormSchemas';
import MaskedInput from '../../AppComponents/MaskedInput';
import MaskedDropDown from '../../AppComponents/MaskedDropdown';
import { withFirebase } from '../../../auth/firebase';
import Spinner from '../../Layout/Spinner';
import {
  CLIENTTYPE,
  OWNERTYPE,
  PROJECTROLE,
  DISCIPLINE,
  WORKNATURE,
  CONCENTRATION,
  NOTIFICATION_PROPS,
  CHANGE_PHONE_NUMBER, LIABILITY,
} from '../../../utils/constants';
import CustomTooltip from '../../AppComponents/CustomTooltip';
import { store } from 'react-notifications-component';
import axios from 'axios';
import {view} from 'react-easy-state';
import {appEasyState} from '../../../easyStateStore';


const EditDetails = ({
  show,
  handleModal,
  firebase,
  user,
  reloadUser,
  disciplines,
  setLoading,
}) => {
  const [company, setCompany] = useState();
  const [website, setWebsite] = useState('');
  const [primaryEmail, setPrimaryEmail] = useState('');
  const [administratorEmail, setAdministratorEmail] = useState('');
  const [secondaryEmail, setSecondaryEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber.slice(-10));
  const [phoneNumberOldValue, setPhoneNumberOldValue] = useState(user.phoneNumber.slice(-10));
  const [businessAddress, setBusinessAddress] = useState('');
  const [municipality, setMunicipality] = useState('');
  const [residenceState, setResidenceState] = useState(user.state || '');
  const [discipline, setDiscipline] = useState('');
  const [concentration, setConcentration] = useState('');
  const [concentrationList, setConcentrationList] = useState([]);
  const [zipCode, setZipCode] = useState('');
  const [edit, setEdit] = useState(false);
  const [otherConcentration, setOtherConcentration] = useState(false);
  const [otherConcentrationValue, setOtherConcentrationValue] = useState('');

  const [states, setStates] = useState([]);
  const [canadianStates, setCanadianStates] = useState([]);
  const [otherState, setOtherState] = useState(user.state === 'Other');
  const [otherStateValue, setOtherStateValue] = useState(user.otherState || '');

  const [professionalLiabilityInsurer, setProfessionalLiabilityInsurer] = useState('');
  const [otherProfessionalLiabilityInsurer, setOtherProfessionalLiabilityInsurer] = useState('');
  const [showOtherProfessionalLiabilityInsurer, setShowOtherProfessionalLiabilityInsurer] = useState(false);


  useEffect(() => {
    firebase.getStates().then((usStates) => {
      setStates(usStates);
    });

    firebase.getCanadianStates().then((caStates) => {
      setCanadianStates(caStates);
    });
  }, []);

  useEffect(() => {
    if (user) {
      let _concentrationList = CONCENTRATION.filter(function (item) {
        return item.id === user.discipline;
      });
      if (_concentrationList.length > 0) {
        setConcentrationList(_concentrationList[0].items);
      }

      setCompany(user.firmName || '');
      setWebsite(user.website || '');
      setPrimaryEmail(user.email || '');
      setAdministratorEmail(user.adminEmail || '');
      setSecondaryEmail(user.secondaryEmail || '');
      setPhoneNumber(user.phoneNumber || '');
      setPhoneNumberOldValue(user.phoneNumber);
      setBusinessAddress(user.address || '');
      setMunicipality(user.municipality || '');
      setResidenceState(user.state || '');
      setDiscipline(user.discipline || '');
      setConcentration(
        user.discipline
          ? user.discipline !== 'landSurveyor'
            ? user.concentration
            : 'N/A'
          : 'N/A');
      setOtherConcentrationValue(user.otherConcentration  || '');
      // setProfessionalLiabilityInsurer(user.liability || '');
      setProfessionalLiabilityInsurer(user.otherLiability !== undefined && user.liability !== undefined
        ? user.liability
        : user.liability
          ? 'Other'
          : ''
      );
      setOtherProfessionalLiabilityInsurer(user.otherLiability !== undefined && user.liability !== undefined
        ? user.otherLiability
        : user.liability
          ? user.liability
          : ''
      );
      setShowOtherProfessionalLiabilityInsurer(user.otherLiability !== undefined && user.liability !== undefined
      ? user.liability === 'Other' : user.liability !== undefined && user.liability !== null && user.liability !== '')
      setZipCode(user.zipCode || '');
      setOtherConcentration(
        user.discipline
          ? user.discipline !== 'landSurveyor'
            ? user.concentration === 'Other'
            : false
          : false
      );
      setOtherStateValue(user.otherState || '');
    }
  }, [show, user])

  console.log('residenceState ------->', residenceState);
  const handleCompanyChange = (e) => {
    console.log('form e.target.value', e)
    console.log('form e.target.value', e.target)

    setCompany(e.target.value);
    // user.company = e.target.value;
  };

  const handleWebsiteChange = (e) => {
    const value = e.target.value
    if (value !== '')
    setWebsite(e.target.value);
    else setWebsite('')
    // user.website = e.target.value;
  }

  const hanldePrimaryEmailChange = (e) => {
    setPrimaryEmail(e.target.value);
    // user.primaryEmail = e.target.value;
  };

  const handleSecondaryEmailChange = (e) => {
    setSecondaryEmail(e.target.value);
    // user.secondaryEmail = e.target.value;
  };

  const handlePhoneNumberChange = (e) => {
    if (e.target.value === '' || e.target.value === '0' || e.target.value === '1')
      setPhoneNumber('')
    else if (/^\d{1,10}$/.test(e.target.value) && !/^[01]/.test(e.target.value))
      setPhoneNumber(e.target.value)
  };

  const handleBusinessAddressChange = (e) => {
    setBusinessAddress(e.target.value);
    // user.businessAddress = e.target.value;
  };

  const handleMunicipalityChange = (e) => {
    setMunicipality(e.target.value);
    // user.municipality = e.target.value;
  };

  const handleAdministratorEmailChange = (e) => {
    setAdministratorEmail(e.target.value);
    // user.administratorEmail = e.target.value;
  };

  // const updateDiscipline = () => {
  //   setEdit(true);
  //   // firebase.updateUser(user.uid, { discipline: discipline });

  //   firebase.getConcentrations(discipline).then((concentrations) => {
  //     setConcentrations(concentrations);
  //     setEdit(false);
  //   });
  // };

  const handleDisciplineChange = (value) => {
    setDiscipline(value);
    // user.discipline = value;
    if (value === 'landSurveyor') {
      // user.concentration = 'N/A'
      setConcentration('N/A');
      setOtherConcentration(false);
      setOtherConcentrationValue(null);
    }
    console.log('disciplines  ===> ', disciplines);
    console.log('value ====> ', value);
    let concentrationList = CONCENTRATION.filter(function (item) {
      return item.id === value;
    });
    console.log('concentrationList ===> ', concentrationList);
    setConcentrationList(concentrationList[0].items);
  };

  const handleResidenceStateChange = (value) => {

    setResidenceState(value);
    // user.state = value;
    if (value === 'Other')
      setOtherState(true);
    else {
      setOtherState(false);
      // user.otherState = null;
    }
  };

  const handleOtherStateChange = (value) => {
    setOtherStateValue(value)
  }

  const handleConcentrationChange = (value) => {
    setOtherConcentration(false);
    setOtherConcentrationValue(null);

    if (value === 'Other') {
      setOtherConcentration(true);
    }
    setConcentration(value);
    // user.concentration = value;
  };

  const handleOtherConcentrationChange = (e) => {
    setOtherConcentrationValue(e.target.value);
    // user.concentration = e.target.value;
  };

  const handleProfessionalLiabilityInsurer = (value) => {
    console.log('value', value)
    setProfessionalLiabilityInsurer(value)
    // setOtherProfessionalLiabilityInsurer('')
    setShowOtherProfessionalLiabilityInsurer(value === 'Other')
  };

  const handleOtherLiabilityChange = (e) => {
    setOtherProfessionalLiabilityInsurer(e.target.value);
  };

  const handleZipCode = (e) => {
    setZipCode(e.target.value);
  };

  return (
    <DynamicModal title="Edit Details" show={show} hideModal={handleModal}>
      <Formik
        initialValues={{
          municipality: user.municipality || '',
          firmName: user.firmName || '',
          email: user.email || '',
          secondaryEmail: user.secondaryEmail || '',
          adminEmail: user.adminEmail || '',
          phoneNumber: user.phoneNumber || '',
          address: user.address || '',
          discipline: user.discipline || '',
          liability: user.liability || '',
          otherLiability: user.otherLiability || '',
          companyEmail: user.companyEmail || '',
          website: user.website || '',
          state: user.state || '',
          otherState: user.otherState || '',
          zipCode: user.zipCode || '',
          concentration: user.concentration || '',
          otherConcentration: user.otherConcentration || '',
        }}
        validationSchema={EDIT_DETAILS_SCHEMA}
        onSubmit={async (_values, { setSubmitting }) => {
          console.log('user.state ==>', user.state)
          console.log('user.OtherState ==>', user.otherState)
          console.log('user.uid ===> ', user.uid);
          console.log('adminEmail values: ', _values)
          console.log('adminEmail: ', _values.adminEmail)
          console.log('adminEmail: ', _values.adminEmail.match(/^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/));

          debugger
          if (administratorEmail.match(/^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/) === null) {
            store.addNotification({
              title: 'Error!',
              message: 'Administrator Email is invalid.',
              type: 'danger',
              ...NOTIFICATION_PROPS,
            });
          }
          else if (secondaryEmail && secondaryEmail !== '' && secondaryEmail.match(/^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/) === null) {
            store.addNotification({
              title: 'Error!',
              message: 'Secondary Email is invalid.',
              type: 'danger',
              ...NOTIFICATION_PROPS,
            });
          }
          else if (!/^\d{10}$/.test(phoneNumber)) {
            store.addNotification({
              title: 'Error!',
              message: 'Phone Number must be 10 digits long.',
              type: 'danger',
              ...NOTIFICATION_PROPS,
            });
            return
          }
          else if (/^[01]/.test(phoneNumber)) {
            store.addNotification({
              title: 'Error!',
              message: 'Phone Number cannot start with 0 or 1.',
              type: 'danger',
              ...NOTIFICATION_PROPS,
            });
            return
          }
          else {

            let error_occurred = false;

            // if (phoneNumber !== '' && phoneNumber.localeCompare(phoneNumberOldValue) !== 0) {
            //   console.log('phoneNumber', phoneNumber)
            //
            //   // await axios.post(CHANGE_PHONE_NUMBER, {
            //   //   apiKey: "sealpact-origami/crewlogix",
            //   //   uid: user.uid,
            //   //   countryCode: "+1",
            //   //   phoneNumber: phoneNumberOldValue,
            //   //   newPhoneNumber: phoneNumber
            //   // }, {
            //   //   headers: {
            //   //     'Content-Type': 'application/json'
            //   //   }
            //   // }).then(resp => resp.data)
            //   //   .then(response => {
            //   //   console.log('phoneNumber response', response)
            //   //
            //   //   if (!response.success) {
            //   //    error_occurred = true
            //   //     store.addNotification({
            //   //       title: 'Error!',
            //   //       message: response.message,
            //   //       type: 'danger',
            //   //       ...NOTIFICATION_PROPS,
            //   //     });
            //   //   }
            //   //   else {
            //   //     _values['phoneNumber'] = phoneNumber
            //   //     user.phoneNumber = phoneNumber
            //   //     setLoading(true)
            //   //   }
            //   // }).catch(err => {
            //   //   console.log('phoneNumber err', err)
            //   //   console.log('phoneNumber err.message', err.message)
            //   //   error_occurred = true
            //   //   store.addNotification({
            //   //     title: 'Error!',
            //   //     message: err.message,
            //   //     type: 'danger',
            //   //     ...NOTIFICATION_PROPS,
            //   //   });
            //   // })
            //
            // }

            if (!error_occurred) {
              // setLoading(true);
              _values['phoneNumber'] = phoneNumber
              user.phoneNumber = phoneNumber

              _values['state'] = residenceState;
              _values['otherState'] = otherStateValue;
              console.log('_values before submitting ==>', _values);
              debugger

              const updatedValues = {
                municipality: municipality,
                firmName: company,
                website: website,
                email: primaryEmail,
                secondaryEmail: secondaryEmail,
                adminEmail: administratorEmail,
                phoneNumber: phoneNumber,
                address: businessAddress,
                discipline: discipline,
                liability: professionalLiabilityInsurer,
                otherLiability: professionalLiabilityInsurer === 'Other' ? otherProfessionalLiabilityInsurer : null,
                state: residenceState,
                otherState: otherStateValue,
                concentration: concentration,
                otherConcentration: otherConcentrationValue,
                zipCode: zipCode,
              }

              firebase
                .updateUser(user.uid, updatedValues)
                .then(async () => {
                  await reloadUser();
                  setSubmitting(false);
                  // setUserInput({
                  //   municipality: updatedValues.municipality || 'N/A',
                  //   companyName: updatedValues.firmName,
                  //   website: updatedValues.website || 'N/A',
                  //   email: updatedValues.email,
                  //   secondaryEmail: updatedValues.secondaryEmail || 'N/A',
                  //   adminEmail: updatedValues.adminEmail,
                  //   phoneNumber: updatedValues.phoneNumber,
                  //   address: updatedValues.address || 'N/A',
                  //   discipline: updatedValues.discipline,
                  //   liability: updatedValues.liability || 'N/A',
                  //   companyEmail: updatedValues.companyEmail,
                  //   state: updatedValues.state ? updatedValues.state !== 'Other' ? updatedValues.state : updatedValues.otherState ? updatedValues.otherState : 'N/A' : 'N/A',
                  //   concentration: updatedValues.discipline
                  //     ? updatedValues.discipline !== 'landSurveyor'
                  //       ? updatedValues.concentration && updatedValues.concentration !== 'Other'
                  //         ? updatedValues.concentration
                  //         : updatedValues.otherConcentration
                  //       : 'N/A'
                  //     : 'N/A',
                  //   zipCode: updatedValues.zipCode || 'N/A',
                  // })
                  //
                  // setRefreshUser(true);
                  appEasyState.profileUpdatedSuccessfully = 'true'
                  localStorage.setItem('profileUpdatedSuccessfully', 'true')
                  localStorage.setItem('userId', user.uid)
                  handleModal();

                  store.addNotification({
                    title: 'Updated Successfully!',
                    message: 'Profile updated successfully.',
                    type: 'default',
                    ...NOTIFICATION_PROPS,
                  });
                  // window.location.reload(false);
                })
                .catch((error) => {
                  setLoading(false);
                  store.addNotification({
                    title: 'Error',
                    message: 'Profile updated unsuccessfully.',
                    type: 'danger',
                    ...NOTIFICATION_PROPS,
                  });
                  console.log('error', error);
                  return;
                });

            }

          }

        }}
      >
        {(props) => {
          return (
            <Form onSubmit={props.handleSubmit}>
              <Container>
                <Row className="d-flex flex-column align-items-center">
                  <Col xs={8} className="mt-3">
                    {/*<MaskedInput*/}
                    {/*  name="firmName"*/}
                    {/*  formikProps={props}*/}
                    {/*  label="Company"*/}
                    {/*  defaultValue={company}*/}
                    {/*  onTextChange={handleCompanyChange}*/}
                    {/*  maxLength={50}*/}
                    {/*/>*/}
                    <Form.Group controlId="firmName">
                      <Form.Label>Company</Form.Label>
                      <Form.Control
                        type='text'
                        name="firmName"
                        defaultValue={company}
                        onChange={handleCompanyChange}
                      />
                    </Form.Group>
                    {/*<MaskedInput*/}
                    {/*  name="website"*/}
                    {/*  formikProps={props}*/}
                    {/*  label="Website"*/}
                    {/*  defaultValue={website}*/}
                    {/*  onTextChange={handleWebsiteChange}*/}
                    {/*  maxLength={50}*/}
                    {/*  content={'Adding your company website is completely optional, and a chance for you to market your own firm for users who verify this SealPact in the future.'}*/}
                    {/*/>*/}
                    <Form.Group controlId="website">
                      <Form.Label>Website</Form.Label>
                      <CustomTooltip trigger={'hover'} location={'auto'} content={'Adding your company website is completely optional, and a chance for you to market your own firm for users who verify this SealPact in the future.'}>
                        <i
                          style={{ marginLeft: '3px', marginTop: '3px' }}
                          className="fa fa-info-circle"
                          aria-hidden="true"
                        />
                      </CustomTooltip>
                      <Form.Control
                        type={'text'}
                        name={"website"}
                        defaultValue={website}
                        onChange={handleWebsiteChange}
                      />
                    </Form.Group>

                    {/*<MaskedInput*/}
                    {/*  name="email"*/}
                    {/*  formikProps={props}*/}
                    {/*  label="Primary Email"*/}
                    {/*  disabled*/}
                    {/*  defaultValue={primaryEmail}*/}
                    {/*  onTextChange={hanldePrimaryEmailChange}*/}
                    {/*  maxLength={50}*/}
                    {/*  content={*/}
                    {/*    'Please Contact support@sealpact.com if you wish to change your primary/login email. Thank you'*/}
                    {/*  }*/}
                    {/*/>*/}

                    <Form.Group controlId="email">
                      <Form.Label>Primary Email</Form.Label>
                      <CustomTooltip trigger={'hover'} location={'auto'} content={'Please Contact support@sealpact.com if you wish to change your primary/login email. Thank you'}>
                        <i
                          style={{ marginLeft: '3px', marginTop: '3px' }}
                          className="fa fa-info-circle"
                          aria-hidden="true"
                        />
                      </CustomTooltip>
                      <Form.Control
                        type={'text'}
                        name={"email"}
                        defaultValue={primaryEmail}
                        onChange={hanldePrimaryEmailChange}
                        disabled
                      />
                    </Form.Group>

                    {/*<MaskedInput*/}
                    {/*  name="secondaryEmail"*/}
                    {/*  formikProps={props}*/}
                    {/*  label="Secondary Email"*/}
                    {/*  defaultValue={secondaryEmail}*/}
                    {/*  onTextChange={handleSecondaryEmailChange}*/}
                    {/*  maxLength={50}*/}
                    {/*/>*/}

                    <Form.Group controlId="secondaryEmail">
                      <Form.Label>Secondary Email</Form.Label>
                      <Form.Control
                        type={'text'}
                        name={"secondaryEmail"}
                        defaultValue={secondaryEmail}
                        onChange={handleSecondaryEmailChange}
                      />
                    </Form.Group>

                    {/*<MaskedInput*/}
                    {/*  name="adminEmail"*/}
                    {/*  formikProps={props}*/}
                    {/*  label="Administrator Email"*/}
                    {/*  defaultValue={administratorEmail}*/}
                    {/*  onTextChange={handleAdministratorEmailChange}*/}
                    {/*  maxLength={50}*/}
                    {/*  content={`This is the person at your firm who heads up SealPact coordination. If you are not sure who this is, use your own email for now.`}*/}
                    {/*/>*/}

                    <Form.Group controlId="adminEmail">
                      <Form.Label>Administrator Email</Form.Label>
                      <CustomTooltip trigger={'hover'} location={'auto'} content={'This is the person at your firm who heads up SealPact coordination. If you are not sure who this is, use your own email for now.'}>
                        <i
                          style={{ marginLeft: '3px', marginTop: '3px' }}
                          className="fa fa-info-circle"
                          aria-hidden="true"
                        />
                      </CustomTooltip>
                      <Form.Control
                        type={'text'}
                        name={"adminEmail"}
                        defaultValue={administratorEmail}
                        onChange={handleAdministratorEmailChange}
                      />
                    </Form.Group>

                    {/*<MaskedInput*/}
                    {/*  name="phoneNumber"*/}
                    {/*  formikProps={props}*/}
                    {/*  label="Phone Number"*/}
                    {/*  defaultValue={phoneNumber}*/}
                    {/*  onTextChange={handlePhoneNumberChange}*/}
                    {/*  maxLength={50}*/}
                    {/*  content={`Please Contact support@sealpact.com if you wish to change your Phone Number. Thank you.`}*/}
                    {/*  disabled*/}
                    {/*/>*/}

                    <Form.Group controlId="phoneNumber">
                      <Form.Label>Phone Number</Form.Label>
                      {/*<CustomTooltip trigger={'hover'} location={'auto'} content={'Please Contact support@sealpact.com if you wish to change your Phone Number. Thank you.'}>*/}
                      {/*  <i*/}
                      {/*    style={{ marginLeft: '3px', marginTop: '3px' }}*/}
                      {/*    className="fa fa-info-circle"*/}
                      {/*    aria-hidden="true"*/}
                      {/*  />*/}
                      {/*</CustomTooltip>*/}
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">+1</InputGroup.Text>
                        <Form.Control
                          type={'text'}
                          name={"phoneNumber"}
                          value={phoneNumber}
                          onChange={handlePhoneNumberChange}
                          // disabled
                        />
                      </InputGroup>

                    </Form.Group>

                    {/*<MaskedInput*/}
                    {/*  name="address"*/}
                    {/*  formikProps={props}*/}
                    {/*  label="Street Address"*/}
                    {/*  defaultValue={businessAddress}*/}
                    {/*  onTextChange={handleBusinessAddressChange}*/}
                    {/*  maxLength={50}*/}
                    {/*/>*/}

                    <Form.Group controlId="address">
                      <Form.Label>Street Address</Form.Label>
                      <Form.Control
                        type={'text'}
                        name={"address"}
                        defaultdefaultValue={businessAddress}
                        onChange={handleBusinessAddressChange}
                      />
                    </Form.Group>

                    {/*<MaskedInput*/}
                    {/*  name="municipality"*/}
                    {/*  formikProps={props}*/}
                    {/*  label="City"*/}
                    {/*  defaultValue={municipality}*/}
                    {/*  onTextChange={handleMunicipalityChange}*/}
                    {/*  maxLength={50}*/}
                    {/*/>*/}

                    <Form.Group controlId="municipality">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type={'text'}
                        name={"municipality"}
                        defaultValue={municipality}
                        onChange={handleMunicipalityChange}
                      />
                    </Form.Group>


                    {/*<MaskedDropDown*/}
                    {/*  controlId="state"*/}
                    {/*  label="State"*/}
                    {/*  onChange={handleResidenceStateChange}*/}
                    {/*  dataArray={states}*/}
                    {/*  user={user}*/}
                    {/*  props={props}*/}
                    {/*  defaultValue={residenceState}*/}
                    {/*/>*/}

                    {/*<Form.Group controlId="temp">*/}
                    {/*  <Form.Label>temp</Form.Label>*/}
                    {/*  <Form.Control*/}
                    {/*    type={'text'}*/}
                    {/*    defaultValue={temp}*/}
                    {/*    onChange={e => setTemp(e.target.value)}*/}
                    {/*  />*/}
                    {/*</Form.Group>*/}

                    <Form.Group controlId="state">
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        as="select"
                        defaultValue={residenceState}
                        onChange={(e) => handleResidenceStateChange(e.target.value)}
                        // onBlur={formikProps.handleChange}
                      >
                        <option value="" selected disabled className={s.fwBold}>
                          US States & Territories
                        </option>
                        {states.map((item, index) => (
                          <option key={index} defaultValue={item.label}>{item.label}</option>
                        ))}
                        <option value="" disabled className={s.fwBold}>
                          Canadian Provinces & Territories
                        </option>
                        {canadianStates.map((item, i) => (
                          <option key={i} defaultValue={item.label}>
                            {item.label}
                          </option>
                        ))}
                        <option value="" disabled className={s.fwBold}>
                          Other State
                        </option>
                        <option value="Other">
                          Other
                        </option>
                      </Form.Control>
                      {/*{formikProps.errors.state && (*/}
                      {/*  <p className={`input-feedback ${s.error}`}>*/}
                      {/*    {formikProps.errors.state}*/}
                      {/*  </p>*/}
                      {/*)}*/}
                    </Form.Group>
                    {otherState && (
                      <Form.Group className={s.inputGroup} controlId="otherState">
                        <Form.Label className={s.boldLabel}>Enter State</Form.Label>
                        <Form.Control
                          type="text"
                          autoFocus
                          onChange={(e) => handleOtherStateChange(e.target.value)}
                          defaultValue={otherStateValue}
                          // onBlur={formikProps.handleChange}
                        />
                        {/*<p className={`input-feedback ${s.error}`}>*/}
                        {/*  {formikProps.errors.otherState}*/}
                        {/*</p>*/}
                      </Form.Group>
                    )}

                    {/*<MaskedInput*/}
                    {/*  name="zipCode"*/}
                    {/*  formikProps={props}*/}
                    {/*  label="Zip Code"*/}
                    {/*  defaultValue={zipCode}*/}
                    {/*  onTextChange={handleZipCode}*/}
                    {/*  maxLength={50}*/}
                    {/*/>*/}

                    <Form.Group controlId="zipCode">
                      <Form.Label>Zip Code</Form.Label>
                      <Form.Control
                        type={'text'}
                        name={"zipCode"}
                        defaultValue={zipCode}
                        onChange={handleZipCode}
                      />
                    </Form.Group>

                    <MaskedDropDown
                      controlId={'discipline'}
                      label={'Discipline'}
                      onChange={handleDisciplineChange}
                      dataArray={disciplines}
                      defaultValue={discipline}
                      user={user}
                      props={props}
                    />

                    {!!concentrationList && (
                      <>
                        {concentrationList.length !== 0 && (
                          <MaskedDropDown
                            controlId={'concentration'}
                            label={'Concentration'}
                            onChange={handleConcentrationChange}
                            dataArray={concentrationList}
                            defaultValue={concentration}
                            user={user}
                            props={props}
                            edit={edit}
                          />
                        )}
                      </>
                    )}

                    {otherConcentration && (
                      <Form.Group controlId="otherConcentration">
                        <Form.Label>Enter Concentration</Form.Label>
                        <Form.Control
                          type={'text'}
                          name={"otherConcentration"}
                          defaultValue={otherConcentrationValue}
                          onChange={handleOtherConcentrationChange}
                        />
                      </Form.Group>
                      // <MaskedInput
                      //   name="otherConcentration"
                      //   label={"Enter Concentration"}
                      //   formikProps={props}
                      //   defaultValue={otherConcentrationValue}
                      //   onTextChange={handleOtherConcentrationChange}
                      //   maxLength={50}
                      // />
                    )}

                    {/*<MaskedInput*/}
                    {/*  name="liability"*/}
                    {/*  formikProps={props}*/}
                    {/*  label="Professional Liability Insurer"*/}
                    {/*  defaultValue={professionalLiabilityInsurer}*/}
                    {/*  onTextChange={handleProfessionalLiabilityInsurer}*/}
                    {/*  maxLength={50}*/}
                    {/*  content={*/}
                    {/*    'This is optional, don’t worry if you don’t know it right now. There may be some benefits that apply, and if so, you can always add this later.'*/}
                    {/*  }*/}
                    {/*/>*/}

                    <Form.Group>
                      <Form.Label>Professional Liability Insurer</Form.Label>
                      <CustomTooltip trigger={'hover'} location={'auto'} content={'This is optional, don’t worry if you don’t know it right now. There may be some benefits that apply, and if so, you can always add this later.'}>
                        <i
                          style={{ marginLeft: '3px', marginTop: '3px' }}
                          className="fa fa-info-circle"
                          aria-hidden="true"
                        />
                      </CustomTooltip>
                      {/*<Form.Control*/}
                      {/*  type={'text'}*/}
                      {/*  name={"liability"}*/}
                      {/*  defaultValue={professionalLiabilityInsurer}*/}
                      {/*  onChange={handleProfessionalLiabilityInsurer}*/}
                      {/*/>*/}
                      <Form.Control
                        as="select"
                        className={s.dropdown}
                        onChange={(e) => handleProfessionalLiabilityInsurer(e.target.value)}
                        defaultValue={professionalLiabilityInsurer}
                      >
                        <option value="" selected disabled>
                          Select
                        </option>
                        {LIABILITY.map((ctId, i) => (
                          <option
                            key={i}
                            value={ctId.value ? ctId.value : ctId}
                            selected={ctId.value === professionalLiabilityInsurer ? 'selected' : null}
                          >
                            {ctId.label ? ctId.label : ctId}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    {showOtherProfessionalLiabilityInsurer && (
                      <Form.Group controlId="otherLiability">
                        <Form.Label>Enter Professional Liability Insurer</Form.Label>
                        <Form.Control
                          type={'text'}
                          name={"otherLiability"}
                          defaultValue={otherProfessionalLiabilityInsurer}
                          onChange={handleOtherLiabilityChange}
                        />
                      </Form.Group>
                    )}
                  </Col>
                </Row>
                <Row className="pt-4 mt-4">
                  <Col
                    xs={12}
                    className={`d-flex justify-content-between ${s.btnStyle}`}
                  >
                    <Button variant="outline-dark" onClick={handleModal}>
                      Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                      Confirm
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form>
          );
        }}
      </Formik>
    </DynamicModal>
  );
};

export default view(EditDetails);
