/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import s from './style.scss';
import DynamicModal from '../../../DynamicModal';
import SealScreen from './SealScreen';
import axios from 'axios';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS, SEND_FLORIDA_SEAL_INFO_EMAIL } from '../../../../utils/constants';

const CreateNewSeal = ({
  firebase,
  user,
  getSealsAndSigns,
  showSpinner,
  isStamp,
  setRefreshSeals,
}) => {

  const [showSealModal, setToggle] = useState(false);

  const handleModal = () => {
    const modalShown = showSealModal;
    setToggle(!showSealModal);
    // if (modalShown)
    //   window.location.href = 'seals';
  };

  const handleOtherState = (boolean) => {};
  const handleSubmit = async (values) => {
    console.log('values ====> ', values);
    debugger
    const params = {};
    params.sealName = values.sealName;
    params.state = values.state;
    if (params.state === 'Other')
      params.otherState = values.otherState;
    params.newLicenseNo = values.newLicenseNo;
    params.expirationDate = values.expirationDate;
    params.data = values.data;
    params.status = true;
    params.createdAt = new Date().getTime();
    params.type = 'stamp';

    if (isStamp) {
      params.approvalStatus = values.approvalStatus;
      params.type = 'submittalReviewStamp';
      delete params.expirationDate;
    }
    console.log('params ==== > ', params);

    isStamp
      ? await firebase
          .createNewSubmittalReviewStamp(user.uid, params)
          .then((res) => {
            console.log('***** in createNewSubmittalSeal: ')
            // getSealsAndSigns();
            setRefreshSeals(true)
            store.addNotification({
              title: 'Created Successfully!',
              message: 'Submittal Review Stamp created successfully.',
              type: 'default',
              ...NOTIFICATION_PROPS,
            });
            store.addNotification({
              title: '',
              message: 'Please wait while the page is being updated.',
              type: 'info',
              ...NOTIFICATION_PROPS,
            });
            return;
          })
          .catch((err) => {
            console.log('error in CreateNewSeal', err);
            store.addNotification({
              title: 'Error',
              message: 'Error in creating Submittal Review Stamp',
              type: 'danger',
              ...NOTIFICATION_PROPS,
            });
            window.location.href = 'seals'
          })
      : await firebase
          .createNewSeal(user.uid, params)
          .then((res) => {
            console.log('***** in createNewSeal')
            // getSealsAndSigns();
            setRefreshSeals(true)
            store.addNotification({
              title: 'Created Successfully!',
              message: 'Seal created successfully.',
              type: 'default',
              ...NOTIFICATION_PROPS,
            });
            store.addNotification({
              title: '',
              message: 'Please wait while the page is being updated.',
              type: 'info',
              ...NOTIFICATION_PROPS,
            });

            if (params.state.toLowerCase() === 'florida') {
              axios.post(
                SEND_FLORIDA_SEAL_INFO_EMAIL,
                {
                  userName: user.firstName + " " + user.lastName,
                  email: user.email,
                  phoneNumber: user.phoneNumber,
                  // sealName: params.sealName,
                  // sealState: params.state,
                  // sealLicenseNumber: params.newLicenseNo,
                  // sealExpirationDate: params.expirationDate
              }).then(resp => {
                console.log('florida seal created and email sent')
              }).catch((err) => {
                console.log('error in email for CreateNewSeal', err);
                store.addNotification({
                  title: 'Error',
                  message: 'Error in sending email for the seal',
                  type: 'danger',
                  ...NOTIFICATION_PROPS,
                });
              });
            }
            return;
          })
          .catch((err) => {
            console.log('error in CreateNewSeal', err);
            store.addNotification({
              title: 'Error',
              message: 'Error in creating Seal',
              type: 'danger',
              ...NOTIFICATION_PROPS,
            });
            window.location.href = 'seals'
          });

    handleModal();
    // showSpinner();
  };
  return (
    <Fragment>
      <Button
        variant="text"
        onClick={handleModal}
        className={isStamp ? s.btnTextStamp : s.btnText}
      >
        <i className="fa fa-plus" aria-hidden="true"></i>
        {isStamp ? 'Create New Stamp' : 'Create New Seal'}
      </Button>
      <DynamicModal
        show={showSealModal}
        title={
          isStamp ? 'Create New Stamp' : 'Create New Seal'
        }
        hideModal={handleModal}
      >
        <SealScreen
          handleModal={handleModal}
          handleSubmit={handleSubmit}
          isStamp={isStamp}
        />
      </DynamicModal>
    </Fragment>
  );
};

export default CreateNewSeal;
