/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Card } from 'react-bootstrap';
import s from './style.scss';
import LogoBrand from '../../LogoBrand';
import { Formik } from 'formik';
import { OnboardingSignUpFormValidationSchema, SignUpFormValidationSchema } from '../../../config/FormSchemas';
import { withFirebase } from '../../../auth/firebase';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS } from '../../../utils/constants';
import {view} from 'react-easy-state';
import { appEasyState } from '../../../easyStateStore';
import DynamicModal from '../../DynamicModal';
import TermsAndConditions from '../../TermsAndPrivcay/TermsAndConditions';
import PrivacyPolicy from '../../TermsAndPrivcay/PrivacyPolicy';
import { useHistory, useLocation } from 'react-router-dom';
import VerificationCard from './verification-card';
import SignUp from './Signup';
import queryString from 'query-string';


const Onboarding = (props) => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const [loading, setLoading] = useState(false);
  const [showTermsModal, termsToggle] = useState(false);
  const [showPrivacyModal, privacyToggle] = useState(false);
  const toggleTermsModal = () => termsToggle(!showTermsModal);
  const togglePrivacyModal = () => privacyToggle(!showPrivacyModal);

  const [signupCardCompleted, setSignupCardCompleted] = useState(false),
    [verificationCardCompleted, setVerificationCardCompleted] = useState(false)


  useEffect(() => {
    if (queryParams) {
      let { uid, customer } = queryParams;
      if (uid) {
        uid = uid.replace(/\s+/g, '+')
        appEasyState.referrerUid = appEasyState.decryptValue(uid)
      }
      if (customer) {
        customer = customer.replace(/\s+/g, '+')
        appEasyState.referrerCustomerId = appEasyState.decryptValue(customer)
      }
    }
  }, [queryParams]);


  const history = useHistory();

  const { currentUser } = props.firebase.auth;
  // if (currentUser && currentUser.email) {
  //   if (currentUser.isOnboardingCompleted === undefined || currentUser.isOnboardingCompleted)
  //     push('dashboard');
  //   else
  //     push('account')
  // }


  useEffect(() => {

    const handleTermination = async (event) => {
      await props.firebase.doSignOut().then(() => {
        console.log('logout 8 called')
        localStorage.setItem('encryptedString', 'null')
        console.log('back clicked')
      }).catch((err) => {
        console.log('handleTermination err', err);
      })
    }

    window.addEventListener('popstate', handleTermination);

    return () => {
      setTimeout(() => {
        window.removeEventListener('popstate', handleTermination);
      }, 1000)
    }
  }, []);

  useEffect(() => {
    props.firebase.doSignOut().then(() => {
      console.log('logout 9 called')
      localStorage.setItem('encryptedString', 'null')
      appEasyState.user = null;
    }).catch((err) => {
      console.log('signup signOut err: ', err)
    });
  }, [])


  async function isNewUser (values) {
    setLoading(true)
    const  { email, phoneNumber, password, verifyPassword } = values
    appEasyState.onboarding = {
      email,
      phoneNumber,
      password,
      verifyPassword,
    }
    console.log('appEasyState.onboarding', appEasyState.onboarding)
    await props.firebase
      .isUserAlreadyExists(email)
      .then(async (userSignInMethods) => {
        if (userSignInMethods.length === 0) {
          setSignupCardCompleted(true)
        } else {
          setLoading(false)
          store.addNotification({
            title: 'Error',
            message: 'User already exists.',
            type: 'danger',
            ...NOTIFICATION_PROPS,
          });
        }
        return;
      })
      .catch((error) => {
        setLoading(false)
        store.addNotification({
          title: 'Error',
          message: error,
          type: 'danger',
          ...NOTIFICATION_PROPS,
        });
        console.log('error ==> ', error);
        return;
      });
  }

  return (
    <Formik
      initialErrors={{
        email: 'Email Not Valid',
      }}
      initialValues={{
        email: appEasyState.onboarding.email,
        password: appEasyState.onboarding.password,
        verifyPassword: appEasyState.onboarding.verifyPassword,
        phoneNumber: appEasyState.onboarding.phoneNumber
      }}
      validationSchema={OnboardingSignUpFormValidationSchema}
      onSubmit={(e) => e.preventDefault()}
    >
      {(formikProps) => {
        const { errors } = formikProps;
        const makeDisable = (array) => {
          var errorArray = array.filter((key) =>
            Object.keys(errors).includes(key),
          );
          const hasErrors = errorArray.length > 0;
          return hasErrors;
        };
        return (
          <Form className={s.backgroundImage} onSubmit={async (event) => {
            event.preventDefault();
            await isNewUser(formikProps.values)
          }}>
            <Container>
              <div className={s.wrapper}>
                <div className={s.headerLogo}>
                  <LogoBrand imgSize={48} className={s.logoStyle} {...props} />
                </div>
                <div className={s.centerClass}>
                  <div className={`${s.marginForm}`}>
                    <div id="Signup" className="d-flex justify-content-center align-items-center">
                      {!signupCardCompleted  && (
                        <Card className={s.onboardingCard}>
                          <div className={s.wrapper}>
                            <Card.Title><span className={s.cardTitle}>Get Started For Free</span></Card.Title>
                            <Card.Subtitle className={s.cardSubtitle}>Please enter your email and password</Card.Subtitle>
                            <Card.Body style={{ padding: '0' }}>
                              <SignUp formikProps={formikProps} />
                              <Button
                                variant="primary"
                                className={s.marginDbl}
                                block
                                disabled={makeDisable([
                                  'password',
                                  'email',
                                  'verifyPassword',
                                  'phoneNumber'
                                ])}
                                type='submit'
                              >
                                {loading ? (
                                  <div>
                                    <span className="spinner-border spinner-border-sm mr-2" />
                                    Signing Up
                                  </div>
                                ) : (
                                  <div>
                                    Sign Up
                                  </div>
                                )}
                              </Button>
                              <center style={{ marginTop: '32px' }}>
                                Already Member?&nbsp;
                                <b
                                  onClick={() => history.push('/login')}
                                  style={{ cursor: 'pointer', color: '#04B5FF', fontWeight: 500 }}
                                >
                                  Sign In
                                </b>
                                <div className={s.termsText}>
                                  By clicking the button above, you agree to our{' '}
                                  <b className={s.termsTextBold} onClick={toggleTermsModal}>Terms of Service</b>{' '}and{' '}
                                  <b className={s.termsTextBold} onClick={togglePrivacyModal}>Privacy Policy</b> (Most Recently Updated on December 3, 2019)
                                </div>
                              </center>
                            </Card.Body>
                            <DynamicModal
                              size="lg"
                              title="Terms of Service"
                              show={showTermsModal}
                              hideModal={toggleTermsModal}
                            >
                              <TermsAndConditions />
                            </DynamicModal>
                            <DynamicModal
                              size="lg"
                              title="Privacy Policy"
                              show={showPrivacyModal}
                              hideModal={togglePrivacyModal}
                            >
                              <PrivacyPolicy />
                            </DynamicModal>
                          </div>
                        </Card>
                      )}
                      {signupCardCompleted && !verificationCardCompleted && (
                        <VerificationCard
                          setLoading={setLoading}
                          setSignupCardCompleted={setSignupCardCompleted}
                          setVerificationCardCompleted={setVerificationCardCompleted}
                          {...props}
                        />
                      )}
                      {signupCardCompleted && verificationCardCompleted && (
                        <Card className={s.onboardingCard}>
                          <div className={s.wrapper}>
                            <Card.Title><span className={s.cardTitle}>Verify Your Email</span></Card.Title>
                            <Card.Subtitle>
                            <span className={s.cardSubtitle}>
                               We have sent link to your email, please verify and login to your account
                            </span>
                            </Card.Subtitle>
                            <Card.Body style={{ padding: 0 }}>
                              <Button
                                variant="primary"
                                className={s.marginDbl}
                                block
                                onClick={async () => {
                                  history.push('/login')
                                }}
                              >
                                Done
                              </Button>
                            </Card.Body>
                          </div>
                        </Card>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

export default withFirebase(view(Onboarding));
