/* eslint-disable indent */
import React, { useState, Fragment, useEffect } from 'react';
import Header from '../../Header';
import UploadDocument from '../UploadDocument';
import SearchInput from '../../SearchInput';
import s from './style.scss';
import { Tabs, Tab, Row, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { All_DOCUMENTS } from '../../../utils/constants';
import ColorLabels from './ColorLabels';
import COLUMNS from './COLUMNS';
import Spinner from '../../Layout/Spinner';
import withAuthorization from '../../../auth/session/withAuthorization';
import { set } from 'local-storage';
import { view } from 'react-easy-state';
import { appEasyState } from '../../../easyStateStore';
import SealProgressBar from '../../Layout/sealProgressBar';

const MyDocuments = (props) => {
  console.log('props in my documents', props);

  //
  const { user, firebase } = props;

  const [key, setKey] = useState('all');
  const [allDocuments, setAllDocuments] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const [parsing, setParsing] = useState(false);

  useEffect(() => {
    console.log('props', props);
    appEasyState.tableLoading = true;

    firebase.getDocsCount(user).then((count) => {
      console.log('count =======> ', count);
      appEasyState.docsCount = count;
      appEasyState.allDocsCount =
        appEasyState.docsCount.pending +
        appEasyState.docsCount.pacted +
        appEasyState.docsCount.invalidated +
        appEasyState.docsCount.shared;

      if (appEasyState.originalData.length > 0)
        if (appEasyState.filteredData.length === 0) {
          appEasyState.filteredData = appEasyState.originalData;
          setAllDocuments(appEasyState.originalData);
          setOriginalData(appEasyState.originalData);
        }
      setLoading(false);
    });

    firebase.getAllDocuments(user).then((documentsArr) => {
      console.log('documentsArr =======> ', documentsArr);
      setAllDocuments(documentsArr);
      setOriginalData(documentsArr);
      appEasyState.filteredData = documentsArr;
      appEasyState.originalData = documentsArr;
    });
  }, []);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        appEasyState.tableLoading = false;
      }, 500);
    }

    if (pageLoading) {
      appEasyState.tableLoading = true;
      setTimeout(() => {
        setAllDocuments(appEasyState.filteredData);
        appEasyState.tableLoading = false;
        setPageLoading(false);
      }, 500);
    }
  }, [loading, pageLoading]);

  const showdocumentTab = (arg) => setKey(arg);

  const getFilteredData = (data) => {
    setAllDocuments(data);
    appEasyState.filteredData = data;
  };
  const getTrProps = (table, rowInfo) => {
    return {
      onClick: (e) => {
        console.log('rowInfo ===> ', rowInfo);

        if (rowInfo.original.status !== 'pacted') {
          props.history.push('/documentDetails', {
            documentSubmitted: true,
            documentDetails: rowInfo.original.data,
            backToDoc: false,
            numPages: rowInfo.original.totalPages,
            fileType: rowInfo.original.fileType,
            documentInformation: rowInfo.original,
            isMyDocuments: true,
          });
        } else {
          props.history.push('/documentDetails', {
            documentSubmitted: true,
            documentDetails: rowInfo.original.sealPactDownloadUrl,
            backToDoc: false,
            numPages: rowInfo.original.totalPages,
            fileType: rowInfo.original.fileType,
            documentInformation: rowInfo.original,
            isMyDocuments: true,
          });
          // props.history.push('/sealpactDetails', {
          //   confirmationValues: rowInfo.original,
          //   documentInformation: rowInfo.original,
          // });
        }
      },
      style: {
        borderRight: 0,
        borderBottom: rowInfo
          ? rowInfo.original
            ? 'Solid black 2px'
            : 'lightgray'
          : null,
        borderLeft: rowInfo
          ? rowInfo.original.status === 'pending' ||
            rowInfo.original.status === 'shared'
            ? 'Solid #fcd325 5px'
            : rowInfo.original.status === 'pacted'
            ? 'Solid #3c9 5px'
            : 'Solid #e64767 5px'
          : null,
      },
    };
  };
  return loading ? (
    <Spinner />
  ) : parsing ? (
    <Fragment>
      <SealProgressBar
        message={'Your document is being flattened please wait.'}
      />
    </Fragment>
  ) : (
    <Fragment>
      <Header title="My Documents" />
      <Row noGutters className={`align-items-center ${s.features} `}>
        <Col className="text-right" style={{ paddingRight: '15px' }}>
          <UploadDocument uploading={(status) => setParsing(status)} />
        </Col>
        <Col xs={12} sm={8} md={5} lg={3}>
          <SearchInput
            array={originalData}
            getFilteredData={getFilteredData}
            searchTarget="name"
          />
        </Col>
        <Col xs={12}>
          <Tabs
            id="docs-tab"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className={`docstable ${s.tabs}`}
          >
            <Tab eventKey="all" title="All" className="docsTab">
              <div className={`tbl  ${s.tblContainer}`}>
                <ReactTable
                  data={allDocuments}
                  columns={COLUMNS}
                  expanded={true}
                  className={s.ReactTable}
                  getTrProps={getTrProps}
                  showPagination={true}
                  loading={appEasyState.tableLoading}
                  // pageSize={appEasyState.allDocs.pageSize}
                  // onPageChange={(pageIndex) => {
                  //   appEasyState.allDocs.pageIndex = pageIndex
                  //   setAllDocuments([])
                  //   appEasyState.tableLoading = true
                  //   setPageLoading(true)
                  // }}
                  // onPageSizeChange={(pageSize) => {
                  //   appEasyState.allDocs.pageSize = pageSize;
                  //   appEasyState.allDocs.pageIndex = 0
                  //   setAllDocuments([])
                  //   appEasyState.tableLoading = true
                  //   setPageLoading(true)
                  // }}
                  // pages={Math.ceil(appEasyState.allDocsCount / appEasyState.allDocs.pageSize)}
                />
              </div>
            </Tab>
            <Tab eventKey="SealPacted" title="SealPacted" className="docsTab">
              <div className={`tbl  ${s.tblContainer}`}>
                <ReactTable
                  data={allDocuments.filter((a) => a.status === 'pacted')}
                  columns={COLUMNS}
                  expanded={true}
                  className={s.ReactTable}
                  getTrProps={getTrProps}
                  showPagination={true}
                  loading={appEasyState.tableLoading}
                  // pageSize={appEasyState.pactedDocs.pageSize}
                  // onPageChange={(pageIndex) => {
                  //   appEasyState.pactedDocs.pageIndex = pageIndex
                  //   setAllDocuments([])
                  //   appEasyState.tableLoading = true
                  //   setPageLoading(true)
                  // }}
                  // onPageSizeChange={(pageSize) => {
                  //   appEasyState.pactedDocs.pageSize = pageSize;
                  //   appEasyState.pactedDocs.pageIndex = 0
                  //   setAllDocuments([])
                  //   appEasyState.tableLoading = true
                  //   setPageLoading(true)
                  // }}
                  // pages={Math.ceil(appEasyState.docsCount.pacted / appEasyState.pactedDocs.pageSize)}
                />
              </div>
            </Tab>
            <Tab eventKey="Pending" title="Pending" className="docsTab">
              <div className={`tbl  ${s.tblContainer}`}>
                <ReactTable
                  data={allDocuments.filter((a) => a.status === 'pending')}
                  columns={COLUMNS}
                  expanded={true}
                  className={s.ReactTable}
                  getTrProps={getTrProps}
                  showPagination={true}
                  loading={appEasyState.tableLoading}
                  // pageSize={appEasyState.pendingDocs.pageSize}
                  // onPageChange={(pageIndex) => {
                  //   appEasyState.pendingDocs.pageIndex = pageIndex
                  //   setAllDocuments([])
                  //   appEasyState.tableLoading = true
                  //   setPageLoading(true)
                  // }}
                  // onPageSizeChange={(pageSize) => {
                  //   appEasyState.pendingDocs.pageSize = pageSize;
                  //   appEasyState.pendingDocs.pageIndex = 0
                  //   setAllDocuments([])
                  //   appEasyState.tableLoading = true
                  //   setPageLoading(true)
                  // }}
                  // pages={Math.ceil(appEasyState.docsCount.pending / appEasyState.pendingDocs.pageSize)}
                />
              </div>
            </Tab>

            <Tab eventKey="Invalidated" title="Invalidated" className="docsTab">
              <div className={`tbl  ${s.tblContainer}`}>
                <ReactTable
                  data={allDocuments.filter((a) => a.status === 'invalidated')}
                  columns={COLUMNS}
                  expanded={true}
                  className={s.ReactTable}
                  getTrProps={getTrProps}
                  showPagination={true}
                  loading={appEasyState.tableLoading}
                  // pageSize={appEasyState.invalidatedDocs.pageSize}
                  // onPageChange={(pageIndex) => {
                  //   appEasyState.invalidatedDocs.pageIndex = pageIndex
                  //   setAllDocuments([])
                  //   appEasyState.tableLoading = true
                  //   setPageLoading(true)
                  // }}
                  // onPageSizeChange={(pageSize) => {
                  //   appEasyState.invalidatedDocs.pageSize = pageSize;
                  //   appEasyState.invalidatedDocs.pageIndex = 0
                  //   setAllDocuments([])
                  //   appEasyState.tableLoading = true
                  //   setPageLoading(true)
                  // }}
                  // pages={Math.ceil(appEasyState.docsCount.invalidated / appEasyState.invalidatedDocs.pageSize)}
                />
              </div>
            </Tab>
          </Tabs>
          <ColorLabels tab={showdocumentTab} />
        </Col>
      </Row>
    </Fragment>
  );
};
const condition = (user) => !!user;
export default withAuthorization(condition)(view(MyDocuments));
