import React from 'react';
import { Col, Nav, NavDropdown, Image, Navbar } from 'react-bootstrap';
import s from './style.scss';
import LogoBrand from '../LogoBrand';
import { SIDEBAR_ITEMS } from '../../utils/constants';
import { withRouter } from 'react-router-dom';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS } from '../../utils/constants';
import moment from 'moment';

const SideBar = (props) => {
  console.log('props in sidebar ===> ', props);
  const {
    history: { push },
    location: { pathname },
    user,
  } = props;

  const redirect = (event, url) => {
    push(url);
    event.preventDefault();
  };
  return (
    <Col xs={12} sm={3} lg={2} xl={2} className={s.colStyle}>
      <div className={s.centerDiv}>
        <LogoBrand imgSize={40} className={s.fontSize} home {...props} />
        <NavDropdown
          className="mobile-dropdown"
          bsPrefix={s.displayMobile}
          title={
            <i
              className={`fa fa-bars fa-2x ${s.removeArrow}`}
              aria-hidden="true"
            />
          }
        >
          {SIDEBAR_ITEMS &&
            SIDEBAR_ITEMS.map((item, key) => {
              if (key < 6) {
                return (
                  <li key={key}>
                    <NavDropdown.Item
                      eventKey={key + 1}
                      onClick={(event) => {

                        if (
                          props.user.subscriptionDetails &&
                          (props.user.subscriptionDetails.status ===
                            'trialing' ||
                            props.user.subscriptionDetails.status ===
                              'active') &&
                          moment
                            .unix(
                              props.user.subscriptionDetails.current_period_end,
                            )
                            .isAfter(moment(new Date()))
                        ) {
                          if (
                            props.user.isOnboardingCompleted !== undefined &&
                            props.user.isOnboardingCompleted === false &&
                            key !== 3
                          ) {
                            store.addNotification({
                              title: 'Onboarding Not Completed',
                              message:
                                'Please setup your account to continue using application.',
                              type: 'warning',
                              ...NOTIFICATION_PROPS,
                            });
                          } else redirect(event, item.url);
                        } else {
                          if (key !== 4) {
                            store.addNotification({
                              title: 'Please subscribe to a plan',
                              message:
                                'Please subscribe to a plan to continue using application.',
                              type: 'warning',
                              ...NOTIFICATION_PROPS,
                            });
                          }
                        }
                      }}
                    >
                      <span>{item.title}</span>
                    </NavDropdown.Item>
                  </li>
                );
              } else if (props.user.admin === true && key === 6) {
                return (
                  <li key={key}>
                    <NavDropdown.Item
                      eventKey={key + 1}
                      onClick={(event) => {

                        if (
                          props.user.subscriptionDetails &&
                          (props.user.subscriptionDetails.status ===
                            'trialing' ||
                            props.user.subscriptionDetails.status ===
                              'active') &&
                          moment
                            .unix(
                              props.user.subscriptionDetails.current_period_end,
                            )
                            .isAfter(moment(new Date()))
                        ) {
                          if (
                            props.user.isOnboardingCompleted !== undefined &&
                            props.user.isOnboardingCompleted === false &&
                            key !== 3
                          ) {
                            store.addNotification({
                              title: 'Onboarding Not Completed',
                              message:
                                'Please setup your account to continue using application.',
                              type: 'warning',
                              ...NOTIFICATION_PROPS,
                            });
                          } else redirect(event, item.url);
                        } else {
                          if (key !== 4) {
                            store.addNotification({
                              title: 'Please subscribe to a plan',
                              message:
                                'Please subscribe to a plan to continue using application.',
                              type: 'warning',
                              ...NOTIFICATION_PROPS,
                            });
                          }
                        }
                      }}
                    >
                      <span>{item.title}</span>
                    </NavDropdown.Item>
                  </li>
                );
              }
            })}
        </NavDropdown>
      </div>

      <Navbar className={s.navBar} sticky="top" expand="lg">
        <Nav variant="tabs" className={`flex-column ${s.displayWeb}`}>
          {SIDEBAR_ITEMS.map((item, key) => {
            if (key < 6) {
              return (
                <div className={s.navItem} key={key}>
                  <Nav.Link
                    className={pathname === item.url ? s.blueBar : s.navLink}
                    href="#"
                    active={pathname === item.url ? true : false}
                    onClick={(event) => {
                      if (
                        props.user.subscriptionDetails &&
                        (props.user.subscriptionDetails.status === 'trialing' ||
                          props.user.subscriptionDetails.status === 'active') &&
                        moment
                          .unix(
                            props.user.subscriptionDetails.current_period_end,
                          )
                          .isAfter(moment(new Date()))
                      ) {
                        if (
                          props.user.isOnboardingCompleted !== undefined &&
                          props.user.isOnboardingCompleted === false &&
                          key !== 3
                        ) {
                          store.addNotification({
                            title: 'Onboarding Not Completed',
                            message:
                              'Please setup your account to continue using application.',
                            type: 'warning',
                            ...NOTIFICATION_PROPS,
                          });
                        } else redirect(event, item.url);
                      } else {
                        if (key !== 4) {
                          store.addNotification({
                            title: 'Please subscribe to a plan',
                            message:
                              'Please subscribe to a plan to continue using application.',
                            type: 'warning',
                            ...NOTIFICATION_PROPS,
                          });
                        }
                      }
                    }}
                  >
                    <Image
                      src={
                        pathname === item.url ? item.activeImgSrc : item.imgSrc
                      }
                      alt="pic"
                      height="25"
                    />
                    <span>{item.title}</span>
                  </Nav.Link>
                </div>
              );
            } else if (props.user.admin === true && key === 6) {
              return (
                <div className={s.navItem} key={key}>
                  <Nav.Link
                    className={pathname === item.url ? s.blueBar : s.navLink}
                    href="#"
                    active={pathname === item.url ? true : false}
                    onClick={(event) => {
                      if (
                        props.user.subscriptionDetails &&
                        (props.user.subscriptionDetails.status === 'trialing' ||
                          props.user.subscriptionDetails.status === 'active') &&
                        moment
                          .unix(
                            props.user.subscriptionDetails.current_period_end,
                          )
                          .isAfter(moment(new Date()))
                      ) {
                        if (
                          props.user.isOnboardingCompleted !== undefined &&
                          props.user.isOnboardingCompleted === false &&
                          key !== 3
                        ) {
                          store.addNotification({
                            title: 'Onboarding Not Completed',
                            message:
                              'Please setup your account to continue using application.',
                            type: 'warning',
                            ...NOTIFICATION_PROPS,
                          });
                        } else redirect(event, item.url);
                      } else {
                        if (key !== 4) {
                          store.addNotification({
                            title: 'Please subscribe to a plan',
                            message:
                              'Please subscribe to a plan to continue using application.',
                            type: 'warning',
                            ...NOTIFICATION_PROPS,
                          });
                        }
                      }
                    }}
                  >
                    <Image
                      src={
                        pathname === item.url ? item.activeImgSrc : item.imgSrc
                      }
                      alt="pic"
                      height="25"
                    />
                    {item.title}
                  </Nav.Link>
                </div>
              );
            }
          })}
        </Nav>
      </Navbar>
    </Col>
  );
};

export default withRouter(SideBar);
