import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import moment from 'moment';
import { appEasyState } from '../../easyStateStore';


//staging
// const config = {
//   apiKey: 'AIzaSyBx06LogVQdwOxzLOoBsdgHIrnLFCqsCtg',
//   authDomain: 'sealpact-d669e.firebaseapp.com',
//   databaseURL: 'https://sealpact-d669e.firebaseio.com',
//   projectId: 'sealpact-d669e',
//   storageBucket: 'sealpact-d669e.appspot.com',
//   messagingSenderId: '164796006658',
//   appId: '1:164796006658:web:c8dffc14201da1996a421b',
//   measurementId: 'G-Z2RJSG0944',
// };

//live
const config = {
  apiKey: 'AIzaSyBXZ1r1PCh_9R5t-p9fyPZUuX8VccsnM-s',
  authDomain: 'sealpact-production.firebaseapp.com',
  databaseURL: 'https://sealpact-production.firebaseio.com',
  projectId: 'sealpact-production',
  storageBucket: 'sealpact-production.appspot.com',
  messagingSenderId: '746230827318',
  appId: '1:746230827318:web:d8f6eb5e31f2f61c08cb97',
  measurementId: 'G-B77SB96CBR',
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.database();
    this.firestore = app.firestore();
    this.RecaptchaVerifier = app.auth.RecaptchaVerifier;
    this.storage = app.storage();
  }


  componentDidMount() {
    this.listener = this.auth.onAuthStateChanged(async (authUser) => {
      await this.auth.setPersistence(app.auth.Auth.Persistence.SESSION);
    });
  }  setSession = () =>
    this.auth.setPersistence(app.auth.Auth.Persistence.SESSION);

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email.toLowerCase(), password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email.toLowerCase(), password);

  addNewUser = async (user) =>
    await this.firestore.collection('users').doc(user.uid).set(user);

  addUserSubscriptionDetails = (userId, subscriptionDetails) =>
    this.firestore.collection('users').doc(userId).update({
      subscriptionDetails: subscriptionDetails,
    });

  deleteUserSubscriptionDetails = (userId) =>
    this.firestore.collection('users').doc(userId).update({
      subscriptionDetails: app.firestore.FieldValue.delete(),
    });

  changeSubscriptionStatus = (userId, status) =>
    this.firestore.collection('users').doc(userId).update({
      'subscriptionDetails.status': status,
    });

  addLastScanDate = (userId, date) =>
    this.firestore.collection('users').doc(userId).update({
      lastScanDate: date,
    });

  addLastReminderEmailDate = (userId, date) =>
    this.firestore.collection('users').doc(userId).update({
      lastReminderEmailDate: date,
    });

  userRef = (uid) => this.firestore.collection('users').doc(uid);

  addLastSealpactDate = async (userId, date) =>
    await this.firestore.collection('users').doc(userId).update({
      lastSealpactDate: date,
    });

  getUserSubscriptionDetails = (userId) =>
    this.firestore
      .collection('users')
      .doc(userId)
      .get()
      .then((querySnapshot) => {
        const arr = [];
        querySnapshot.forEach((doc) => {
          arr.push(doc.data());
        });
        return arr.subscriptionDetails;
      });

  sendVerificationEmail = () => this.auth.currentUser.sendEmailVerification();
  getCurrentUser = () => {
    return this.auth.currentUser;
  };

  getStates = () =>
    this.firestore
      .collection('states')
      .orderBy('label', 'asc')
      .get()
      .then((querySnapshot) => {
        const arr = [];
        querySnapshot.forEach((doc) => {
          arr.push({ label: doc.data().label, value: doc.data().label });
        });
        return arr;
      });

  getCanadianStates = () =>
    this.firestore
      .collection('canadianStates')
      .orderBy('label', 'asc')
      .get()
      .then((querySnapshot) => {
        const caStatesArr = [];
        querySnapshot.forEach((doc) => {
          caStatesArr.push({
            label: doc.data().label,
            value: doc.data().label,
          });
        });
        return caStatesArr;
      });

  getSeals = (userId) =>
    this.firestore
      .collection('users')
      .doc(userId)
      .collection('seals')
      .get()
      .then((querySnapshot) => {
        const arr = [];
        querySnapshot.forEach((doc) => {
          arr.push(doc.data());
        });
        return arr;
      });

  getStamps = (userId) =>
    this.firestore
      .collection('users')
      .doc(userId)
      .collection('stamps')
      .get()
      .then((querySnapshot) => {
        const arr = [];
        querySnapshot.forEach((doc) => {
          arr.push(doc.data());
        });
        return arr;
      });

  getSignatures = (userId) =>
    this.firestore
      .collection('users')
      .doc(userId)
      .collection('signatures')
      .get()
      .then((querySnapshot) => {
        const arr = [];
        querySnapshot.forEach((doc) => {
          arr.push(doc.data());
        });
        return arr;
      });

  getDisciplines = () =>
    this.firestore
      .collection('disciplines')
      .get()
      .then((querySnapshot) => {
        const arr = [];
        querySnapshot.forEach((doc) => {
          arr.push({ label: doc.data().label, value: doc.data().value });
        });
        return arr;
      });

  getConcentrations = (disciplineValue) => {
    return this.firestore
      .collection('disciplines')
      .where('value', '==', disciplineValue)
      .get()
      .then((querySnapshot) => {
        return querySnapshot.docs[0].ref
          .collection('concentrations')
          .get()
          .then((snapShot) => {
            return snapShot.docs[0].data().items;
          });
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
  };
  createNewSeal = (uid, seal) => {
    console.log('uid', uid);
    console.log('seal', seal);

    return new Promise((resolve, reject) => {
      seal.id = new Date().getTime();
      const filePath = '/' + uid + '/seals/' + seal.id;
      return this.storage
        .ref()
        .child(filePath)
        .putString(seal.data, 'data_url')
        .then((uploadedRes) => {
          uploadedRes.ref
            .getDownloadURL()
            .then((downloadUrl) => {
              console.log('downloadUrl ==> ', downloadUrl);
              seal.data = downloadUrl;
              return this.firestore
                .collection('users')
                .doc(uid)
                .collection('seals')
                .doc()
                .set(seal)
                .then((dataRes) => {
                  return resolve(dataRes);
                })
                .catch((error) => {
                  console.log('error ===> ', error);
                  return;
                });
            })
            .catch((error) => {
              console.log('error', error);
              return;
            });
        })
        .catch((error) => {
          console.log('error ===> ', error);
          return;
        });
    });
  };

  createNewSubmittalReviewStamp = (uid, seal) => {
    return new Promise((resolve, reject) => {
      seal.id = new Date().getTime();
      const filePath = '/' + uid + '/stamps/' + seal.id;
      return this.storage
        .ref()
        .child(filePath)
        .putString(seal.data, 'data_url')
        .then((uploadedRes) => {
          uploadedRes.ref
            .getDownloadURL()
            .then((downloadUrl) => {
              console.log('downloadUrl ==> ', downloadUrl);
              seal.data = downloadUrl;
              return this.firestore
                .collection('users')
                .doc(uid)
                .collection('stamps')
                .doc()
                .set(seal)
                .then((dataRes) => {
                  return resolve(dataRes);
                })
                .catch((error) => {
                  console.log('error ===> ', error);
                  return;
                });
            })
            .catch((error) => {
              console.log('error', error);
              return;
            });
        })
        .catch((error) => {
          console.log('error ===> ', error);
          return;
        });
    });
  };

  createNewSignature = (uid, sign) => {
    return new Promise((resolve, reject) => {
      sign.id = new Date().getTime();
      const filePath = '/' + uid + '/signatures/' + sign.id;

      sign.id = new Date().getTime();
      return this.storage
        .ref()
        .child(filePath)
        .putString(sign.data, 'data_url')
        .then((uploadedRes) => {
          uploadedRes.ref
            .getDownloadURL()
            .then((downloadUrl) => {
              sign.data = downloadUrl;

              return this.firestore
                .collection('users')
                .doc(uid)
                .collection('signatures')
                .doc()
                .set(sign)
                .then((dataRes) => {
                  return resolve(dataRes);
                })
                .catch((error) => {
                  console.log('error ===> ', error);
                  return;
                });
            })
            .catch((error) => {
              console.log('error', error);
              return;
            });
        })
        .catch((error) => {
          console.log('error ===> ', error);
          return;
        });
    });
  };

  updateSealAndSignStatusWithImage = (
    id,
    sealId,
    newValue,
    isSeal,
    isSealInfo,
  ) => {
    console.log('updateSealAndSignStatusWithImage id', id);
    console.log('updateSealAndSignStatusWithImage sealId', sealId);
    console.log('updateSealAndSignStatusWithImage newValue', newValue);
    console.log('updateSealAndSignStatusWithImage isSeal', isSeal);
    console.log('updateSealAndSignStatusWithImage isSealInfo', isSealInfo);

    try {
      const sealStatus = moment(newValue.expirationDate).isSameOrAfter(
        moment(new Date()),
        'day',
      )
        ? !newValue.status
        : newValue.status;

      let old_filepath, filePath;
      newValue.id = new Date().getTime();

      if (newValue.type === 'seal') {
        old_filepath = '/' + id + '/seals/' + sealId;
        filePath = '/' + id + '/seals/' + newValue.id;
      } else if (newValue.type === 'stamp') {
        old_filepath = '/' + id + '/seals/' + sealId;
        filePath = '/' + id + '/seals/' + newValue.id;
      } else if (newValue.type === 'signature') {
        old_filepath = '/' + id + '/signatures/' + sealId;
        filePath = '/' + id + '/signatures/' + newValue.id;
      }

      return this.storage
        .ref()
        .child(filePath)
        .putString(newValue.data, 'data_url')
        .then(async (uploadedRes) => {
          console.log(
            'updateSealAndSignStatusWithImage uploadedRes',
            uploadedRes,
          );
          console.log(
            'updateSealAndSignStatusWithImage uploadedRes.ref',
            uploadedRes.ref,
          );
          await uploadedRes.ref
            .getDownloadURL()
            .then((downloadUrl) => {
              newValue.data = downloadUrl;
              console.log(
                'updateSealAndSignStatusWithImage downloadUrl',
                downloadUrl,
              );

              console.log(
                'updateSealAndSignStatusWithImage filePath',
                filePath,
              );
              console.log(
                'updateSealAndSignStatusWithImage old_filepath',
                old_filepath,
              );
              console.log(
                'updateSealAndSignStatusWithImage newValue.data',
                newValue.data,
              );

              this.storage
                .ref()
                .child(old_filepath)
                .getDownloadURL()
                .then((response) => {
                  if (response) {
                    console.log(
                      'updateSealAndSignStatusWithImage getDownloadURL() response',
                      response,
                    );
                    this.storage
                      .ref()
                      .child(old_filepath)
                      .delete()
                      .then((deleteRes) => {
                        console.log('deleteRes', deleteRes);
                      })
                      .catch((error) => {
                        console.log('error 4', error);
                        return;
                      });
                  }
                })
                .catch((error) => {
                  console.log('error 4', error);
                });

              if (isSeal) {
                if (!isSealInfo) {
                  return this.firestore
                    .collection('users')
                    .doc(id)
                    .collection('seals')
                    .get()
                    .then((querySnapshot) => {
                      querySnapshot.forEach((doc) => {
                        if (doc.data().id === sealId) {
                          this.firestore
                            .collection('users')
                            .doc(id)
                            .collection('seals')
                            .doc(doc.id)
                            .update({
                              id: newValue.id,
                              status: sealStatus,
                              expirationDate: newValue.expirationDate,
                              data: newValue.data,
                            })
                            .then((update_resp) => {})
                            .catch((error) => {
                              console.log('error 6', error);
                              return;
                            });
                        }
                      });
                      return;
                    })
                    .catch((error) => {
                      console.log('error 5', error);
                      return;
                    });
                } else {
                  return this.firestore
                    .collection('users')
                    .doc(id)
                    .collection('seals')
                    .get()
                    .then((querySnapshot) => {
                      querySnapshot.forEach((doc) => {
                        if (doc.data().id === sealId) {
                          this.firestore
                            .collection('users')
                            .doc(id)
                            .collection('seals')
                            .doc(doc.id)
                            .update({
                              id: newValue.id,
                              expirationDate: newValue.expirationDate,
                              data: newValue.data,
                            })
                            .then((update_resp) => {})
                            .catch((error) => {
                              console.log('error 6', error);
                              return;
                            });
                        }
                      });
                      return;
                    });
                }
              } else {
                return this.firestore
                  .collection('users')
                  .doc(id)
                  .collection('signatures')
                  .get()
                  .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                      if (doc.data().id === sealId) {
                        this.firestore
                          .collection('users')
                          .doc(id)
                          .collection('signatures')
                          .doc(doc.id)
                          .update({
                            id: newValue.id,
                            status: !newValue.status,
                            data: newValue.data,
                          })
                          .then((update_resp) => {})
                          .catch((error) => {
                            console.log('error 6', error);
                            return;
                          });
                      }
                    });
                    return;
                  })
                  .catch((error) => {
                    console.log('error in get', error);
                    return;
                  });
              }
            })
            .catch((error) => {
              console.log('error 3', error);
              return;
            });
        })
        .catch((error) => {
          console.log('error 2', error);
          return;
        });
    } catch (error) {
      console.log('error 1', error);
      return;
    }
  };

  updateSealAndSignStatus = (id, sealId, newValue, isSeal, isSealInfo) => {
    const sealStatus = moment(newValue.expirationDate).isSameOrAfter(
      moment(new Date()),
      'day',
    )
      ? !newValue.status
      : newValue.status;
    try {
      if (isSeal) {
        if (!isSealInfo) {
          return this.firestore
            .collection('users')
            .doc(id)
            .collection('seals')
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                if (doc.data().id === sealId) {
                  this.firestore
                    .collection('users')
                    .doc(id)
                    .collection('seals')
                    .doc(doc.id)
                    .update({
                      status: sealStatus,
                      expirationDate: newValue.expirationDate,
                    });
                }
              });
              return;
            });
        } else {
          return this.firestore
            .collection('users')
            .doc(id)
            .collection('seals')
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                if (doc.data().id === sealId) {
                  this.firestore
                    .collection('users')
                    .doc(id)
                    .collection('seals')
                    .doc(doc.id)
                    .update({
                      expirationDate: newValue.expirationDate,
                    });
                }
              });
              return;
            });
        }
      } else {
        return this.firestore
          .collection('users')
          .doc(id)
          .collection('signatures')
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              if (doc.data().id === sealId) {
                this.firestore
                  .collection('users')
                  .doc(id)
                  .collection('signatures')
                  .doc(doc.id)
                  .update({
                    status: !newValue.status,
                  });
              }
            });
            return;
          });
      }
    } catch (e) {
      console.error('Error while updating user', e);
    }
  };

  updateSubmittalReviewStampWithImage = (id, sealId, newValue) => {
    try {
      let old_filepath = '/' + id + '/stamps/' + sealId;
      newValue.id = new Date().getTime();
      let filePath = '/' + id + '/stamps/' + newValue.id;

      return this.storage
        .ref()
        .child(filePath)
        .putString(newValue.data, 'data_url')
        .then(async (uploadedRes) => {
          await uploadedRes.ref.getDownloadURL().then((downloadUrl) => {
            newValue.data = downloadUrl;

            this.storage
              .ref()
              .child(old_filepath)
              .getDownloadURL()
              .then((response) => {
                if (response) {
                  console.log(
                    'updateSubmittalReviewStampWithImage getDownloadURL() response',
                    response,
                  );
                  this.storage
                    .ref()
                    .child(old_filepath)
                    .delete()
                    .then((deleteRes) => {
                      console.log('deleteRes', deleteRes);
                    })
                    .catch((error) => {
                      console.log(
                        'updateSubmittalReviewStampWithImage error 2',
                        error,
                      );
                      return;
                    });
                }
              })
              .catch((error) => {
                console.log(
                  'updateSubmittalReviewStampWithImage error 1',
                  error,
                );
              });

            return this.firestore
              .collection('users')
              .doc(id)
              .collection('stamps')
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  if (doc.data().id === sealId) {


                    this.firestore
                      .collection('users')
                      .doc(id)
                      .collection('stamps')
                      .doc(doc.id)
                      .update({
                        id: newValue.id,
                        approvalStatus: newValue.approvalStatus,
                        data: newValue.data,
                      })
                      .then((resu) => {
                        console.log('resu ===> ', resu);
                      })
                      .catch((error) => {
                        console.log('error', error);
                      });
                  }
                });
                return;
              });
          });
        });
    } catch (e) {
      console.error('Error while updating user', e);
    }
  };

  updateSubmittalReviewStamp = (id, sealId, newValue) => {
    try {
      return this.firestore
        .collection('users')
        .doc(id)
        .collection('stamps')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (doc.data().id === sealId) {


              this.firestore
                .collection('users')
                .doc(id)
                .collection('stamps')
                .doc(doc.id)
                .update({
                  approvalStatus: newValue.approvalStatus,
                  isActive: true,
                })
                .then((resu) => {
                  console.log('resu ===> ', resu);
                })
                .catch((error) => {
                  console.log('error', error);
                });
            }
          });
          return;
        });
    } catch (e) {
      console.error('Error while updating user', e);
    }
  };

  activateOrDeactivateSubmittalReviewStamp = (id, sealId, newValue) => {
    try {
      let isActive = false;
      if (newValue.status !== undefined) isActive = !newValue.status;

      return this.firestore
        .collection('users')
        .doc(id)
        .collection('stamps')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (doc.data().id === sealId) {


              this.firestore
                .collection('users')
                .doc(id)
                .collection('stamps')
                .doc(doc.id)
                .update({
                  isActive,
                })
                .then((resu) => {
                  console.log('resu ===> ', resu);
                })
                .catch((error) => {
                  console.log('error', error);
                });
            }
          });
          return;
        });
    } catch (e) {
      console.error('Error while updating user', e);
    }
  };

  getSealAndSignatureDetails = (object, id) => {
    try {
      if (object.type === 'stamp') {
        return this.firestore
          .collection('users')
          .doc(id)
          .collection('seals')
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              if (doc.data().sealName === object.sealName) {
                return doc.data();
              }
            });
            return;
          })
          .catch((error) => {
            console.log('error', error);
            return;
          });
      } else {
        return this.firestore
          .collection('users')
          .doc(id)
          .collection('signatures')
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              if (doc.data().signatureName === object.signatureName) {
                return doc.data();
              }
            });
            return;
          });
      }
    } catch (e) {
      console.error('Error while updating user', e);
    }
  };

  // createNewSignature = (uid, signature) =>
  //   this.firestore
  //     .collection('users')
  //     .doc(uid)
  //     .collection('signatures')
  //     .doc()
  //     .set(signature);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) =>
    this.auth.sendPasswordResetEmail(email.toLowerCase());
  doConfirmPasswordReset = (code, newPassword) =>
    this.auth.confirmPasswordReset(code, newPassword);

  doUpdateVerifyEmail = (code) => this.auth.applyActionCode(code);

  doPasswordUpdate = (currentPassword, newPassword) => {
    const user = this.auth.currentUser;
    const credential = app.auth.EmailAuthProvider.credential(
      this.auth.currentUser.email,
      currentPassword,
    );
    return user
      .reauthenticateAndRetrieveDataWithCredential(credential)
      .then((res) => {
        this.auth.currentUser.updatePassword(newPassword);
      });
  };

  // *** User API ***
  // user = uid => this.db.ref(`users/${uid}`);
  // users = () => this.db.ref("users");

  user = (uid) =>
    this.firestore
      .collection('users')
      .doc(uid)
      .get()
      .then((userData) => {
        if (userData.data()) {
          return this.getProfilePicture(userData.data().uid)
            .then((userProfileUrl) => {
              const userJSON = { ...userData.data(), image: userProfileUrl };
              return userJSON;
            })
            .catch((error) => {
              const userJSON = { ...userData.data() };
              return userJSON;
            });
        } else {
          const userJSON = userData.data();
          return userJSON;
        }
      });

  // addNewDiscipline = discp =>
  //   this.firestore
  //     .collection('disciplines')
  //     .doc()
  //     .set(discp);

  updateUser = (id, newValue) => {
    console.log('newValue ===> ', newValue);
    try {
      return this.firestore.collection('users').doc(id).update(newValue);
    } catch (e) {
      console.error('Error while updating user', e);
    }
  };

  uploadUserProfilePicture = (uid, base64String) => {
    const filePath = '/' + uid + '/profilePicture';
    console.log({ filePath, base64String });

    return this.storage
      .ref()
      .child(filePath)
      .putString(base64String, 'data_url');
  };

  deleteUserProfilePicture = (uid) => {
    return new Promise((resolve, reject) => {
      const filePath = '/' + uid + '/profilePicture';
      return this.storage
        .ref()
        .child(filePath)
        .delete()
        .then((deleteRes) => {
          // return userRef
          //   .update({
          //     image: this.firestore.FieldValue.delete(),
          //   })
          //   .then(deleteRes => {
          //     resolve(deleteRes);
          //     return;
          //   })
          //   .catch(error => {
          //     reject(error);
          //     return;
          //   });
          resolve(deleteRes);
          return;
        })
        .catch((error) => {
          reject(error);
          return;
        });
      // const userRef = this.firestore.collection('users').doc(uid);
      // return userRef
      //   .get()
      //   .then(userData => {
      //     const data = userData.data();
      //     console.log('data <<>> ', data);
      //     if (data.image) {

      //     }
      //     reject(userData);
      //   })
      //   .catch(error => {
      //     reject(error);
      //     return;
      //   });
    });
  };

  getProfilePicture = (uid) => {
    const filePath = '/' + uid + '/profilePicture';
    return this.storage.ref().child(filePath).getDownloadURL();
  };

  uploadNewDocument = (
    uid,
    documentBase64,
    documentInformation,
    user,
    emails,
  ) => {
    return new Promise((resolve, reject) => {
      const filePath = '/' + uid + '/documents/' + documentInformation.id;
      return this.storage
        .ref()
        .child(filePath)
        .putString(documentBase64, 'data_url')
        .then((documentResponse) => {
          documentResponse.ref.getDownloadURL().then((downloadUrl) => {
            const addDocumentInfo = {
              ...documentInformation,
              data: downloadUrl,
              status: 'pending',
              downloadUrl: downloadUrl,
            };
            return this.firestore
              .collection('users')
              .doc(uid)
              .collection('documents')
              .doc()
              .set(addDocumentInfo)
              .then((saveRes) => {
                // user.releadUser();

                resolve(addDocumentInfo);
                return;
              })
              .catch((error) => {
                reject(error);
                return;
              });
          });
        })
        .catch((error) => {
          reject(error);
          return;
        });
    });
  };

  deleteSeal = (uid, sealDetail) => {
    return new Promise(async (resolve, reject) => {
      var collectionName = '';
      if (sealDetail.type === 'stamp') {
        collectionName = 'seals';
      } else if (sealDetail.type === 'signature') {
        collectionName = 'signatures';
      } else if (sealDetail.type === 'submittalReviewStamp') {
        collectionName = 'stamps';
      }
      const filePath = '/' + uid + '/' + collectionName + '/' + sealDetail.id;
      await this.storage
        .ref()
        .child(filePath)
        .delete()
        .then((deleteRes) => {})
        .catch((error) => {
          // reject(error);
          // return;
        });

      return this.firestore
        .collection('users')
        .doc(uid)
        .collection(collectionName)
        .where('id', '==', sealDetail.id)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.size === 0) {
            resolve();
            return;
          }
          querySnapshot.forEach((document) => {
            document.ref
              .delete()
              .then((deletedDocumentRes) => {
                resolve(deletedDocumentRes);
                return;
              })
              .catch((error) => {
                reject(error);
                return;
              });
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  deleteDocument = (uid, documentInformation) => {
    return new Promise((resolve, reject) => {
      const filePath = '/' + uid + '/documents/' + documentInformation.id;
      return this.storage
        .ref()
        .child(filePath)
        .delete()
        .then((deleteRes) => {
          return this.firestore
            .collection('users')
            .doc(uid)
            .collection('documents')
            .where('id', '==', documentInformation.id)
            .get()
            .then((querySnapshot) => {
              if (querySnapshot.size === 0) {
                resolve();
                return;
              }
              querySnapshot.forEach((document) => {
                document.ref
                  .delete()
                  .then((deletedDocumentRes) => {
                    resolve(deletedDocumentRes);
                    return;
                  })
                  .catch((error) => {
                    reject(error);
                    return;
                  });
              });
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
          return;
        });
    });
  };
  deleteSealPactedDocument = (uid, documentInformation) => {
    return new Promise((resolve, reject) => {
      const filePath = '/' + uid + '/sealpacted/' + documentInformation.id;
      return this.storage
        .ref()
        .child(filePath)
        .delete()
        .then((deleteRes) => {
          return this.firestore
            .collection('users')
            .doc(uid)
            .collection('sealpacted')
            .where('id', '==', documentInformation.id)
            .get()
            .then((querySnapshot) => {
              if (querySnapshot.size === 0) {
                resolve();
                return;
              }
              querySnapshot.forEach((document) => {
                document.ref
                  // .update({downloadUrl:""})
                  .update({ sealPactDownloadUrl: null })
                  .then((deletedDocumentRes) => {
                    resolve(deletedDocumentRes);
                    return;
                  })
                  .catch((error) => {
                    reject(error);
                    return;
                  });
              });
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
          return;
        });
    });
  };
  deleteInvalidatedDocument = (uid, documentInformation) => {
    return new Promise((resolve, reject) => {
      const filePath = '/' + uid + '/sealpacted/' + documentInformation.id;
      return this.storage
        .ref()
        .child(filePath)
        .delete()
        .then((deleteRes) => {
          return this.firestore
            .collection('users')
            .doc(uid)
            .collection('invalidated')
            .where('id', '==', documentInformation.id)
            .get()
            .then((querySnapshot) => {
              if (querySnapshot.size === 0) {
                resolve();
                return;
              }
              querySnapshot.forEach((document) => {
                document.ref
                  .update({ sealPactDownloadUrl: null })
                  .then((deletedDocumentRes) => {
                    resolve(deletedDocumentRes);
                    return;
                  })
                  .catch((error) => {
                    reject(error);
                    return;
                  });
              });
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
          return;
        });
    });
  };

  uploadSharedDocument = (documentBase64, documentInformation, emails) => {
    console.log('documentInformation while sharing => ', documentInformation);
    return new Promise((resolve, reject) => {
      console.log('emails ==> ', emails);
      const id = new Date().getTime();
      const filePath = '/sharedDocuments/' + id;
      function isDataURL(s) {
        return !!s.match(isDataURL.regex);
      }
      isDataURL.regex =
        /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;

      return this.storage
        .ref()
        .child(filePath)
        .putString(
          documentBase64,
          isDataURL(documentBase64) ? 'data_url' : 'base64',
        )
        .then((documentResponse) => {
          documentResponse.ref.getDownloadURL().then((downloadUrl) => {
            const addDocumentInfo = {
              ...documentInformation,
              isShared: true,
              status: 'pending',
              downloadUrl: downloadUrl,
              data: downloadUrl,
              id: id,
            };
            emails.forEach((val, index) => {
              addDocumentInfo.sealPacterType = val.sealPacterType;
              return this.firestore
                .collection('users')
                .where('email', '==', val.email)
                .get()
                .then((querySnapshot) => {
                  if (querySnapshot.size !== 0) {
                    querySnapshot.forEach((docu) => {
                      docu.ref
                        .collection('documents')
                        .doc()
                        .set(addDocumentInfo)
                        .then((response) => {
                          resolve(response);
                        });
                    });
                  }

                  return;
                })
                .catch((error) => {
                  reject(error);
                  return;
                });
            });
          });
        })
        .catch((error) => {
          reject(error);
          return;
        });
    });
  };

  uploadForm = (document, form, formType, uid) => {
    return new Promise((resolve, reject) => {
      let filePath;
      if (formType === 'contract') filePath = '/' + uid + '/forms/contract';
      else if (formType === 'subConsultantContract')
        filePath = '/' + uid + '/forms/subConsultantContract';
      else if (formType === 'siform') filePath = '/' + uid + '/forms/siform';

      return this.storage
        .ref()
        .child(filePath)
        .put(form)
        .then((documentResponse) => {
          documentResponse.ref.getDownloadURL().then((downloadUrl) => {
            return this.firestore
              .collection('users')
              .doc(uid)
              .collection('documents')
              .where('id', '==', document.id)
              .get()
              .then((querySnapshot) => {
                if (querySnapshot.size === 0) {
                  console.log('document not found');
                  resolve(downloadUrl);
                  return;
                }
                querySnapshot.forEach((docu) => {
                  docu.ref
                    .update({
                      [formType === 'contract'
                        ? 'contractUrl'
                        : formType === 'subConsultantContract'
                        ? 'subConsultantContractUrl'
                        : 'siformUrl']: downloadUrl,
                    })
                    .then((res) => {
                      resolve(downloadUrl);
                      return;
                    });
                });

                return;
              });
          });
        });
    });
  };

  uploadSealPactedDocument = (uid, document, documentInformation, base64) => {
    console.log('document ===>', document);
    console.log('documentInformation ====> ', documentInformation);
    return new Promise(async (resolve, reject) => {
      const filePath = '/' + uid + '/sealpacted/' + document.id;
      function isDataURL(s) {
        return !!s.match(isDataURL.regex);
      }
      isDataURL.regex =
        /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
      var metadata = {
        contentType: document.fileType,
      };
      try {
        const uploadTask = await this.storage
          .ref()
          .child(filePath)
          .putString(
            base64,
            isDataURL(base64) ? 'data_url' : 'base64',
            metadata,
          );

        const documentResponse = await uploadTask;

        const downloadUrl = await documentResponse.ref.getDownloadURL();

        const addDocumentInfo = {
          ...document,
          ...documentInformation,
          sealPactDownloadUrl: downloadUrl,
        };

        await this.firestore
          .collection('users')
          .doc(uid)
          .collection('sealpacted')
          .doc()
          .set(addDocumentInfo);

        const querySnapshot = await this.firestore
          .collection('users')
          .doc(uid)
          .collection('documents')
          .where('id', '==', document.id)
          .get();

        await this.addLastSealpactDate(uid, new Date().getTime());

        if (querySnapshot.size === 0) {
          resolve(addDocumentInfo);
        } else {
          querySnapshot.forEach(async (docu) => {
            await docu.ref.delete();
          });
          resolve(addDocumentInfo);
        }
      } catch (error) {
        console.error('Error occurred:', error);
        reject(error);
      }
    });
  };

  updateDocumentDetailsInSealPactedDocument = (uid, documentId, values) => {
    return new Promise((resolve, reject) => {
      return this.firestore
        .collection('users')
        .doc(uid)
        .collection('sealpacted')
        .where('id', '==', documentId)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.size > 0) {
            querySnapshot.forEach((doc) => {
              doc.ref
                .set(values, { merge: true })
                .then((saveRes) => {
                  this.getSealPactedDocument(uid, documentId)
                    .then((doc) => {
                      resolve(doc);
                      return;
                    })
                    .catch((error) => {
                      console.log('error === > 3 => ', error);
                      reject(error);
                      return;
                    });

                  resolve(values);
                  return;
                })
                .catch((error) => {
                  console.log('error === > 2 => ', error);
                  reject(error);
                  return;
                });
            });
            return;
          } else {
            reject(querySnapshot);
            return;
          }
        })
        .catch((error) => {
          console.log('error === > 1 => ', error);
          reject(error);
          return;
        });
    });
  };

  uploadSecondarySealPactedDocument = (
    uid,
    document,
    documentInformation,
    base64,
  ) => {
    console.log('document ===>', document);
    console.log('documentInformation ====> ', documentInformation);
    return new Promise((resolve, reject) => {
      const filePath = '/' + uid + '/sealpacted/' + document.id;
      function isDataURL(s) {
        return !!s.match(isDataURL.regex);
      }
      isDataURL.regex =
        /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
      var metadata = {
        contentType: document.fileType,
      };
      return this.storage
        .ref()
        .child(filePath)
        .putString(base64, isDataURL(base64) ? 'data_url' : 'base64', metadata)
        .then((documentResponse) => {
          documentResponse.ref.getDownloadURL().then((downloadUrl) => {
            console.log('downloadUrl -----> ', downloadUrl);
            const addDocumentInfo = {
              ...document,
              ...documentInformation,
              sealPactDownloadUrl: downloadUrl,
            };
            return this.firestore
              .collection('users')
              .doc(uid)
              .collection('documents')
              .where('id', '==', document.id)
              .get()
              .then((querySnapshot) => {
                if (querySnapshot.size > 0) {
                  querySnapshot.forEach((doc) => {
                    doc.ref
                      .set(addDocumentInfo)
                      .then((saveRes) => {
                        // user.releadUser();
                        resolve(addDocumentInfo);
                        return;
                      })
                      .catch((error) => {
                        console.log('error === > 2 => ', error);
                        reject(error);
                        return;
                      });
                  });
                  return;
                } else {
                  reject(querySnapshot);
                  return;
                }
              });
          });
        })
        .catch((error) => {
          reject(error);
          return;
        });
    });
  };

  uploadViewedDocument = (uid, document) => {
    console.log('document ===> ', document);
    return new Promise((resolve, reject) => {
      // user.releadUser();
      return this.firestore
        .collection('users')
        .doc(uid)
        .collection('documents')
        .where('id', '==', document.id)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.size === 0) {
            return;
          }
          querySnapshot.forEach((docu) => {
            docu.ref.update({ status: 'viewed' }).then((response) => {
              resolve(response);
            });
          });

          return;
        })
        .catch((error) => {
          console.log('error ===> 1 ', error);
          reject(error);
        });
    });
  };

  getSealPactedDocument = (uid, documentId) => {
    return new Promise((resolve, reject) => {
      return this.firestore
        .collection('users')
        .doc(uid)
        .collection('sealpacted')
        .where('id', '==', documentId)
        .get()
        .then((querySnapshot) => {
          console.log('querySnapshot', querySnapshot);
          if (querySnapshot.size > 0) {
            querySnapshot.forEach((doc) => {
              resolve(doc.data());
            });
            return;
          } else {
            reject(querySnapshot);
            return;
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getSecondarySealPactedDocument = (uid, documentId) => {
    return new Promise((resolve, reject) => {
      return this.firestore
        .collection('users')
        .doc(uid)
        .collection('documents')
        .where('id', '==', documentId)
        .get()
        .then((querySnapshot) => {
          console.log('querySnapshot', querySnapshot);
          if (querySnapshot.size > 0) {
            querySnapshot.forEach((doc) => {
              console.log('doc.data()==? ', doc.data());
              resolve(doc.data());
            });
            return;
          } else {
            reject();
            return;
          }
        })
        .catch((error) => {
          console.log('error in getting secondary sealpact data: ', error);
          reject(error);
        });
    });
  };

  getInvalidatedDocument = (uid, documentId) => {
    return new Promise((resolve, reject) => {
      return this.firestore
        .collection('users')
        .doc(uid)
        .collection('invalidated')
        .where('id', '==', documentId)
        .get()
        .then((querySnapshot) => {
          console.log('querySnapshot', querySnapshot);
          if (querySnapshot.size > 0) {
            querySnapshot.forEach((doc) => {
              resolve(doc.data());
            });
            return;
          } else {
            resolve(false);
            return;
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  reSealPact = (uid, documentInformation, confirmationValues) => {
    console.log('Resealpact');
    console.log(`documentInformation ===> `, documentInformation);
    console.log(`confirmationValues ===> `, confirmationValues);

    return new Promise((resolve, reject) => {
      const idToDelete = documentInformation.id;

      const addDocumentInfo = {
        ...documentInformation,
        ...confirmationValues,
        dateReSealpacted: new Date().getTime(),
        status: 'pacted',
        isInvalidated: false,
        // downloadUrl: downloadUrl,
      };
      return this.firestore
        .collection('users')
        .doc(uid)
        .collection('sealpacted')
        .doc()
        .set(addDocumentInfo)
        .then((saveRes) => {
          // user.releadUser();

          return this.firestore
            .collection('users')
            .doc(uid)
            .collection('invalidated')
            .where('id', '==', idToDelete)
            .get()
            .then((querySnapshot) => {
              if (querySnapshot.size === 0) {
                resolve();
                return;
              }
              querySnapshot.forEach((docu) => {
                docu.ref.delete().then((response) => {
                  resolve();
                  return;
                });
              });

              return;
            });
        })
        .catch((error) => {
          reject(error);
          return;
        });
    });
  };

  uploadInvalidatedDocument = (
    uid,
    documentInformation,
    confirmationValues,
  ) => {

    console.log('confirmationValues ===> ', confirmationValues);
    console.log('documentInformation ===> ', documentInformation);
    console.log(`documentInformation.id ===> `, typeof documentInformation.id);
    return new Promise((resolve, reject) => {
      const idToDelete = documentInformation.id;
      // const filePath = '/' + uid + '/invalidated/' + id;
      // return this.storage
      //   .ref()
      //   .child(filePath)
      //   .putString(document.data, 'data_url')
      //   .then(documentResponse => {
      //     documentResponse.ref.getDownloadURL().then(downloadUrl => {
      const addDocumentInfo = {
        ...documentInformation,
        ...confirmationValues,
        dateInvalidated: new Date().getTime(),
        status: 'invalidated',
        isInvalidated: true,
        invalidationReason: documentInformation.invalidationReason,

        // downloadUrl: downloadUrl,
      };
      return this.firestore
        .collection('users')
        .doc(uid)
        .collection('invalidated')
        .doc()
        .set(addDocumentInfo, { merge: true })
        .then((saveRes) => {
          // user.releadUser();

          return this.firestore
            .collection('users')
            .doc(uid)
            .collection('sealpacted')
            .where('id', '==', idToDelete)
            .get()
            .then((querySnapshot) => {
              if (querySnapshot.size === 0) {
                resolve(addDocumentInfo);
                return;
              }
              querySnapshot.forEach((docu) => {
                docu.ref.delete().then((response) => {
                  resolve(addDocumentInfo);
                  return;
                });
              });

              return;
            });
        })
        .catch((error) => {
          reject(error);
          return;
        });
      //   });
      // })
      // .catch(error => {
      //   reject(error);
      //   return;
      // });
    });
  };

  getQrCodeScanDetails = (documentId, userId, status) => {
    return new Promise((resolve, reject) => {
      const collectionName =
        status === 'invalidated' ? 'invalidated' : 'sealpacted';
      return this.firestore
        .collection('users')
        .doc(userId)
        .collection(collectionName)
        .where('id', '==', documentId)
        .get()
        .then((querySnapshot) => {

          if (querySnapshot.size === 0) {
            resolve([]);
            return;
          }
          querySnapshot.forEach((docu) => {
            if (docu.data().subscribers) {
              resolve(docu.data().subscribers);
            } else {
              resolve([]);
            }
            return;
          });

          return;
        })
        .catch((error) => {
          console.log('error ===> 1 ', error);
          reject(error);
        });
    });
  };

  saveQrCodeScanDetails = (documentId, userId, status, userDetails) => {

    return new Promise((resolve, reject) => {
      const collectionName =
        status === 'invalidated' ? 'invalidated' : 'sealpacted';
      this.getQrCodeScanDetails(documentId, userId, status)
        .then((subs) => {
          console.log(`subs of subscribe in firebase.js===> `, subs);


          subs.push(userDetails);
          return this.firestore
            .collection('users')
            .doc(userId)
            .collection(collectionName)
            .where('id', '==', documentId)
            .get()
            .then((querySnapshot) => {

              if (querySnapshot.size === 0) {
                reject(0);
                return;
              }
              this.addLastScanDate(userId, new Date().getTime())
                .then(() => {
                  querySnapshot.forEach((docu) => {
                    docu.ref
                      .update({ subscribers: subs })
                      .then((saveRes) => {
                        resolve(saveRes);
                        return;
                      })
                      .catch((error) => {
                        reject(error);
                        return;
                      });
                  });
                })
                .catch((error) => {
                  console.log(`error`, error);
                });

              return;
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {});

      //   });
      // })
      // .catch(error => {
      //   reject(error);
      //   return;
      // });
    });
  };

  isUserAlreadyExists = (userEmail) => {
    return new Promise((resolve, reject) => {
      return this.auth
        .fetchSignInMethodsForEmail(userEmail.toLowerCase())
        .then((signInMethods) => {
          if (signInMethods.length === 0) {
            resolve(signInMethods);
            return;
          }
          resolve(signInMethods);
          return;
        })
        .catch((error) => {
          reject(error);
          return;
        });
    });
  };

  getAllDocuments = (user) => {
    return new Promise((resolve, reject) => {
      this.firestore
        .collection('users')
        .doc(user.uid)
        .collection('documents')
        .get()
        .then((querySnapshot) => {
          const arr = [];
          querySnapshot.forEach((doc) => {
            arr.push({
              ...doc.data(),
              status: doc.data().status ? doc.data().status : 'pending',
            });
          });

          this.firestore
            .collection('users')
            .doc(user.uid)
            .collection('sealpacted')
            .get()
            .then((querySnapshotSealPacted) => {
              querySnapshotSealPacted.forEach((doc) => {
                if (doc.data().sealPactDownloadUrl) {
                  arr.push({ ...doc.data(), status: 'pacted' });
                }
              });
              this.firestore
                .collection('users')
                .doc(user.uid)
                .collection('invalidated')
                .get()
                .then((querySnapshotSealPacted) => {
                  querySnapshotSealPacted.forEach((doc) => {
                    if (doc.data().sealPactDownloadUrl) {
                      arr.push({ ...doc.data(), status: 'invalidated' });
                    }
                  });
                  this.firestore
                    .collection('users')
                    .doc(user.uid)
                    .collection('shared')
                    .get()
                    .then((querySnapshotSealPacted) => {
                      querySnapshotSealPacted.forEach((doc) => {
                        arr.push({ ...doc.data(), status: 'shared' });
                      });
                      resolve(arr);
                    });
                });
            });
        });
    });
  };

  getDocsCount = (user) => {
    return new Promise((resolve, reject) => {
      this.firestore
        .collection('users')
        .doc(user.uid)
        .collection('documents')
        .where('status', '==', 'pending')
        .get()
        .then((querySnapshot) => {
          let docsCount = {
            pending: 0,
            pacted: 0,
            invalidated: 0,
            shared: 0,
          };
          docsCount.pending = querySnapshot.size;

          this.firestore
            .collection('users')
            .doc(user.uid)
            .collection('sealpacted')
            .get()
            .then((querySnapshotSealPacted) => {
              docsCount.pacted = querySnapshotSealPacted.size;

              this.firestore
                .collection('users')
                .doc(user.uid)
                .collection('invalidated')
                .get()
                .then((querySnapshotInvalidated) => {
                  docsCount.invalidated = querySnapshotInvalidated.size;

                  this.firestore
                    .collection('users')
                    .doc(user.uid)
                    .collection('shared')
                    .get()
                    .then((querySnapshotShared) => {
                      docsCount.shared = querySnapshotShared.size;
                      resolve(docsCount);
                    });
                });
            });
        });
    });
  };

  // changeEmailAddress = (uid, email) => {
  //   return new Promise(async (resolve, reject) => {
  //
  //     await this.auth.updateUser(uid, {
  //       email
  //     })
  //       .then(record => record.toJSON())
  //       .then(userRecord => {
  //         console.log('Successfully updated user', userRecord);
  //         resolve(userRecord);
  //       })
  //       .catch(err => {
  //         console.log('changeEmailAddress err', err)
  //         reject(err);
  //       })
  //   })
  // }

  resetTableValuesNotLoading = () => {
    appEasyState.originalData = [];
    appEasyState.filteredData = [];
    appEasyState.showOriginal = false;
    appEasyState.docsCount = {
      pending: 0,
      pacted: 0,
      invalidated: 0,
      shared: 0,
    };
    appEasyState.allDocsCount = 0;
    appEasyState.allDocs = {
      loading: false,
      pageIndex: 0,
      pageSize: 20,
      paginatedData: [],
      sortConfig: {
        columnId: 'dateUploaded',
        sortBy: [],
        colIndex: 5,
      },
    };
    appEasyState.pendingDocs = {
      loading: false,
      pageIndex: 0,
      pageSize: 20,
      paginatedData: [],
      sortConfig: {
        columnId: 'dateUploaded',
        sortBy: [],
        colIndex: 5,
      },
    };
    appEasyState.pactedDocs = {
      loading: false,
      pageIndex: 0,
      pageSize: 20,
      paginatedData: [],
      sortConfig: {
        columnId: 'dateUploaded',
        sortBy: [],
        colIndex: 5,
      },
    };
    appEasyState.invalidatedDocs = {
      loading: false,
      pageIndex: 0,
      pageSize: 20,
      paginatedData: [],
      sortConfig: {
        columnId: 'dateUploaded',
        sortBy: [],
        colIndex: 5,
      },
    };
    appEasyState.sharedDocs = {
      loading: false,
      pageIndex: 0,
      pageSize: 20,
      paginatedData: [],
      sortConfig: {
        columnId: 'dateUploaded',
        sortBy: [],
        colIndex: 5,
      },
    };
  };

  getDocuments = async (
    user,
    docType,
    pageIndex,
    pageSize,
    searchTerm = '',
    orderBy = 'dateUploaded',
    sortBy = 'asc',
  ) => {
    console.log('getDocs user.uid', user.uid);
    console.log('getDocs docType', docType);

    const userRef = this.firestore.collection('users').doc(user.uid);
    const documentsRef = userRef.collection('documents');
    const sealPactedRef = userRef.collection('sealpacted');
    const invalidatedRef = userRef.collection('invalidated');
    const sharedRef = userRef.collection('shared');

    console.log('getDocs pageIndex =======> ', pageIndex);
    console.log('getDocs pageSize =======> ', pageSize);
    console.log('getDocs order =======> ', { orderBy, sortBy, docType });
    console.log(
      'getDocs pageIndex * pageSize + 1 =======> ',
      pageIndex * pageSize + 1,
    );

    this.getDocsCount(user).then((count) => {
      console.log('getDocs count =======> ', count);
      appEasyState.docsCount = count;
      appEasyState.allDocsCount =
        appEasyState.docsCount.pending +
        appEasyState.docsCount.pacted +
        appEasyState.docsCount.invalidated +
        appEasyState.docsCount.shared;

      if (appEasyState.allDocsCount === 0) {
        return new Promise(async (resolve, reject) => {
          this.resetTableValuesNotLoading();
          resolve([]);
        });
      }
    });

    if (docType === 'all') {
      return new Promise(async (resolve, reject) => {
        let arr = [];
        if (pageIndex * pageSize < appEasyState.docsCount.pending) {
          const pendingOffset = pageIndex * pageSize,
            pendingLimit = Math.min(
              pageSize,
              appEasyState.docsCount.pending - pendingOffset,
            );

          if (pendingOffset >= 0 && pendingLimit > 0) {
            const startAtDoc = await documentsRef
              .orderBy(orderBy, 'asc')
              .limit(pendingOffset + 1)
              .get()
              .then((querySnapshot) => querySnapshot.docs[pendingOffset]);

            const startAtValue = startAtDoc ? startAtDoc.data()[orderBy] : null;

            if (startAtValue) {
              await documentsRef
                .orderBy(orderBy, 'asc')
                .startAt(startAtValue)
                .limit(pendingLimit)
                .get()
                .then(async (querySnapshot) => {
                  console.log('querySnapshot =======> ', querySnapshot);
                  console.log(
                    'querySnapshot.size =======> ',
                    querySnapshot.size,
                  );
                  querySnapshot.forEach((doc) => {
                    arr.push({
                      ...doc.data(),
                      status: doc.data().status ? doc.data().status : 'pending',
                    });
                  });
                })
                .catch((error) => {
                  reject(error);
                });
            }
          }

          if (
            pageIndex * pageSize + pageSize >
            appEasyState.docsCount.pending
          ) {
            const pactedOffset = 0;
            const pactedLimit = Math.min(
              appEasyState.docsCount.pacted,
              pageSize - arr.length,
            );

            if (pactedOffset >= 0 && pactedLimit > 0) {
              const startAtDocPacted = await sealPactedRef
                .orderBy(orderBy, 'asc')
                .limit(pactedOffset + 1)
                .get()
                .then((querySnapshot) => querySnapshot.docs[pactedOffset]);

              const startAtValuePacted = startAtDocPacted
                ? startAtDocPacted.data()[orderBy]
                : null;

              if (startAtValuePacted) {
                await sealPactedRef
                  .orderBy(orderBy, 'asc')
                  .startAt(startAtValuePacted)
                  .limit(pactedLimit)
                  .get()
                  .then(async (querySnapshot) => {
                    console.log('querySnapshot =======> ', querySnapshot);
                    console.log(
                      'querySnapshot.size =======> ',
                      querySnapshot.size,
                    );
                    querySnapshot.forEach((doc) => {
                      arr.push({
                        ...doc.data(),
                        status: doc.data().status
                          ? doc.data().status
                          : 'pacted',
                      });
                    });
                  })
                  .catch((error) => {
                    reject(error);
                  });
              }
            }
          }

          if (
            pageIndex * pageSize + pageSize >
            appEasyState.docsCount.pending + appEasyState.docsCount.pacted
          ) {
            const invalidatedOffset = 0;
            const invalidatedLimit = Math.min(
              appEasyState.docsCount.invalidated,
              pageSize - arr.length,
            );

            if (invalidatedOffset >= 0 && invalidatedLimit > 0) {
              const startAtDocInvalidated = await invalidatedRef
                .orderBy(orderBy, 'asc')
                .limit(invalidatedOffset + 1)
                .get()
                .then((querySnapshot) => querySnapshot.docs[invalidatedOffset]);

              const startAtValueInvalidated = startAtDocInvalidated
                ? startAtDocInvalidated.data()[orderBy]
                : null;

              if (startAtValueInvalidated) {
                await invalidatedRef
                  .orderBy(orderBy, 'asc')
                  .startAt(startAtValueInvalidated)
                  .limit(invalidatedLimit)
                  .get()
                  .then(async (querySnapshot) => {
                    console.log('querySnapshot =======> ', querySnapshot);
                    console.log(
                      'querySnapshot.size =======> ',
                      querySnapshot.size,
                    );
                    querySnapshot.forEach((doc) => {
                      arr.push({
                        ...doc.data(),
                        status: doc.data().status
                          ? doc.data().status
                          : 'invalidated',
                      });
                    });
                  })
                  .catch((error) => {
                    reject(error);
                  });
              }
            }
          }

          if (
            pageIndex * pageSize + pageSize >
            appEasyState.docsCount.pending +
              appEasyState.docsCount.pacted +
              appEasyState.docsCount.invalidated
          ) {
            const sharedOffset = 0;
            const sharedLimit = Math.min(
              appEasyState.docsCount.shared,
              pageSize - arr.length,
            );

            if (sharedOffset >= 0 && sharedLimit > 0) {
              const startAtDocShared = await sharedRef
                .orderBy(orderBy, 'asc')
                .limit(sharedOffset + 1)
                .get()
                .then((querySnapshot) => querySnapshot.docs[sharedOffset]);

              const startAtValueShared = startAtDocShared
                ? startAtDocShared.data()[orderBy]
                : null;

              if (startAtDocShared) {
                await sharedRef
                  .orderBy(orderBy, 'asc')
                  .startAt(startAtValueShared)
                  .limit(sharedLimit)
                  .get()
                  .then(async (querySnapshot) => {
                    console.log('querySnapshot =======> ', querySnapshot);
                    console.log(
                      'querySnapshot.size =======> ',
                      querySnapshot.size,
                    );
                    querySnapshot.forEach((doc) => {
                      arr.push({
                        ...doc.data(),
                        status: doc.data().status
                          ? doc.data().status
                          : 'shared',
                      });
                    });
                  })
                  .catch((error) => {
                    reject(error);
                  });
              }
            }
          }

          console.log('before sort all arr =======> ', arr);
          if (sortBy === 'desc') {
            console.log('in desc =======> ');
            arr.sort((a, b) =>
              typeof b[orderBy] === 'string'
                ? b[orderBy].localeCompare(a[orderBy])
                : b[orderBy] - a[orderBy],
            );
            console.log('sorted =======> ');
            console.log('after sort all arr =======> ', arr);
          }

          resolve(arr);
        } else if (
          pageIndex * pageSize <
          appEasyState.docsCount.pending + appEasyState.docsCount.pacted
        ) {
          const pendingOffset = pageIndex * pageSize,
            pendingLimit = appEasyState.docsCount.pending - pendingOffset;

          console.log('=======> pending pageIndex', pageIndex);
          console.log(
            '=======> pending (pageIndex - 1) * pageSize',
            pageIndex * pageSize,
          );
          console.log('=======> pendingOffset', pendingOffset);
          console.log('=======> pendingLimit', pendingLimit);

          if (pendingOffset >= 0 && pendingLimit > 0) {
            const startAtDocPending = await documentsRef
              .orderBy(orderBy, 'asc')
              .limit(pendingOffset + 1)
              .get()
              .then((querySnapshot) => querySnapshot.docs[pendingOffset]);

            const startAtValuePending = startAtDocPending
              ? startAtDocPending.data()[orderBy]
              : null;

            if (startAtValuePending) {
              await documentsRef
                .orderBy(orderBy, 'asc')
                .startAt(startAtValuePending)
                .limit(pendingLimit)
                .get()
                .then((querySnapshot) => {
                  console.log('querySnapshot =======> ', querySnapshot);
                  console.log(
                    'querySnapshot.size =======> ',
                    querySnapshot.size,
                  );
                  querySnapshot.forEach((doc) => {
                    arr.push({
                      ...doc.data(),
                      status: doc.data().status ? doc.data().status : 'pending',
                    });
                  });
                })
                .catch((error) => {
                  reject(error);
                });
            }
          }

          const offset =
            (pageIndex - 1) * pageSize -
            (appEasyState.docsCount.pending % pageSize);

          const pactedOffset = offset <= 0 ? 0 : offset,
            pactedLimit = Math.min(
              pageSize - arr.length,
              appEasyState.docsCount.pacted - pactedOffset,
            );

          console.log('=======> pending pactedOffset', pactedOffset);
          console.log('=======> pending pactedOffset', pactedLimit);

          if (pactedOffset >= 0 && pactedLimit > 0) {
            const startAtDocPacted = await sealPactedRef
              .orderBy(orderBy, 'asc')
              .limit(pactedOffset + 1)
              .get()
              .then((querySnapshot) => querySnapshot.docs[pactedOffset]);

            const startAtValuePacted = startAtDocPacted
              ? startAtDocPacted.data()[orderBy]
              : null;

            if (startAtValuePacted) {
              await sealPactedRef
                .orderBy(orderBy, 'asc')
                .startAt(startAtValuePacted)
                .limit(pactedLimit)
                .get()
                .then((querySnapshot) => {
                  console.log('querySnapshot =======> ', querySnapshot);
                  console.log(
                    'querySnapshot.size =======> ',
                    querySnapshot.size,
                  );
                  querySnapshot.forEach((doc) => {
                    arr.push({
                      ...doc.data(),
                      status: doc.data().status ? doc.data().status : 'pacted',
                    });
                  });
                })
                .catch((error) => {
                  reject(error);
                });
            }

            if (
              pageIndex * pageSize + pageSize >
              appEasyState.docsCount.pacted
            ) {
              const invalidatedOffset = 0;
              const invalidatedLimit = Math.min(
                appEasyState.docsCount.invalidated,
                pageSize - arr.length,
              );

              if (invalidatedOffset >= 0 && invalidatedLimit > 0) {
                const startAtDocInvalidated = await invalidatedRef
                  .orderBy(orderBy, 'asc')
                  .limit(invalidatedOffset + 1)
                  .get()
                  .then(
                    (querySnapshot) => querySnapshot.docs[invalidatedOffset],
                  );

                const startAtValueInvalidated = startAtDocInvalidated
                  ? startAtDocInvalidated.data()[orderBy]
                  : null;

                if (startAtValueInvalidated) {
                  await invalidatedRef
                    .orderBy(orderBy, 'asc')
                    .startAt(startAtValueInvalidated)
                    .limit(invalidatedLimit)
                    .get()
                    .then(async (querySnapshot) => {
                      console.log('querySnapshot =======> ', querySnapshot);
                      console.log(
                        'querySnapshot.size =======> ',
                        querySnapshot.size,
                      );
                      querySnapshot.forEach((doc) => {
                        arr.push({
                          ...doc.data(),
                          status: doc.data().status
                            ? doc.data().status
                            : 'invalidated',
                        });
                      });
                    })
                    .catch((error) => {
                      reject(error);
                    });
                }
              }
            }

            if (
              pageIndex * pageSize + pageSize >
              appEasyState.docsCount.pacted + appEasyState.docsCount.invalidated
            ) {
              const invalidatedOffset = 0;
              const invalidatedLimit = Math.min(
                appEasyState.docsCount.invalidated,
                pageSize - arr.length,
              );

              if (invalidatedOffset >= 0 && invalidatedLimit > 0) {
                const startAtDocInvalidated = await invalidatedRef
                  .orderBy(orderBy, 'asc')
                  .limit(invalidatedOffset + 1)
                  .get()
                  .then(
                    (querySnapshot) => querySnapshot.docs[invalidatedOffset],
                  );

                const startAtValueInvalidated = startAtDocInvalidated
                  ? startAtDocInvalidated.data()[orderBy]
                  : null;

                if (startAtValueInvalidated) {
                  await invalidatedRef
                    .orderBy(orderBy, 'asc')
                    .startAt(startAtValueInvalidated)
                    .limit(invalidatedLimit)
                    .get()
                    .then(async (querySnapshot) => {
                      console.log('querySnapshot =======> ', querySnapshot);
                      console.log(
                        'querySnapshot.size =======> ',
                        querySnapshot.size,
                      );
                      querySnapshot.forEach((doc) => {
                        arr.push({
                          ...doc.data(),
                          status: doc.data().status
                            ? doc.data().status
                            : 'invalidated',
                        });
                      });
                    })
                    .catch((error) => {
                      reject(error);
                    });
                }
              }
            }

            if (
              pageIndex * pageSize + pageSize >=
              appEasyState.docsCount.invalidated + appEasyState.docsCount.shared
            ) {
              const sharedOffset = 0;
              const sharedLimit = Math.min(
                appEasyState.docsCount.shared,
                pageSize - arr.length,
              );

              if (sharedOffset >= 0 && sharedLimit > 0) {
                const startAtDocShared = await sharedRef
                  .orderBy(orderBy, 'asc')
                  .limit(sharedOffset + 1)
                  .get()
                  .then((querySnapshot) => querySnapshot.docs[sharedOffset]);

                const startAtValueShared = startAtDocShared
                  ? startAtDocShared.data()[orderBy]
                  : null;

                if (startAtValueShared) {
                  await sharedRef
                    .orderBy(orderBy, 'asc')
                    .startAt(startAtValueShared)
                    .limit(sharedLimit)
                    .get()
                    .then(async (querySnapshot) => {
                      console.log('querySnapshot =======> ', querySnapshot);
                      console.log(
                        'querySnapshot.size =======> ',
                        querySnapshot.size,
                      );
                      querySnapshot.forEach((doc) => {
                        arr.push({
                          ...doc.data(),
                          status: doc.data().status
                            ? doc.data().status
                            : 'shared',
                        });
                      });
                    })
                    .catch((error) => {
                      reject(error);
                    });
                }
              }
            }
          }

          console.log('before sort all arr =======> ', arr);
          if (sortBy === 'desc') {
            console.log('in desc =======> ');
            arr.sort((a, b) =>
              typeof b[orderBy] === 'string'
                ? b[orderBy].localeCompare(a[orderBy])
                : b[orderBy] - a[orderBy],
            );
            console.log('sorted =======> ');
            console.log('after sort all arr =======> ', arr);
          }

          resolve(arr);
        } else if (
          pageIndex * pageSize <
          appEasyState.docsCount.pending +
            appEasyState.docsCount.pacted +
            appEasyState.docsCount.invalidated
        ) {
          const pactedOffset = pageIndex * pageSize,
            pactedLimit = appEasyState.docsCount.pacted - pactedOffset;

          if (pactedOffset >= 0 && pactedLimit > 0) {
            const startAtDocPacted = await sealPactedRef
              .orderBy(orderBy, 'asc')
              .limit(pactedOffset + 1)
              .get()
              .then((querySnapshot) => querySnapshot.docs[pactedOffset]);

            const startAtValuePacted = startAtDocPacted
              ? startAtDocPacted.data()[orderBy]
              : null;

            if (startAtValuePacted) {
              await sealPactedRef
                .orderBy(orderBy, 'asc')
                .startAt(startAtValuePacted)
                .limit(pactedLimit)
                .get()
                .then((querySnapshot) => {
                  console.log('querySnapshot =======> ', querySnapshot);
                  console.log(
                    'querySnapshot.size =======> ',
                    querySnapshot.size,
                  );
                  querySnapshot.forEach((doc) => {
                    arr.push({
                      ...doc.data(),
                      status: doc.data().status ? doc.data().status : 'pacted',
                    });
                  });
                })
                .catch((error) => {
                  reject(error);
                });
            }
          }

          const offset =
            (pageIndex - 1) * pageSize -
            (appEasyState.docsCount.pacted % pageSize);

          const invalidatedOffset = offset <= 0 ? 0 : offset,
            invalidatedLimit = Math.min(
              pageSize - arr.length,
              appEasyState.docsCount.invalidated - invalidatedOffset,
            );

          if (invalidatedOffset >= 0 && invalidatedLimit > 0) {
            const startAtDocInvalidated = await invalidatedRef
              .orderBy(orderBy, 'asc')
              .limit(invalidatedOffset + 1)
              .get()
              .then((querySnapshot) => querySnapshot.docs[invalidatedOffset]);

            const startAtValueInvalidated = startAtDocInvalidated
              ? startAtDocInvalidated.data()[orderBy]
              : null;

            if (startAtValueInvalidated) {
              await invalidatedRef
                .orderBy(orderBy, 'asc')
                .startAt(startAtValueInvalidated)
                .limit(invalidatedLimit)
                .get()
                .then((querySnapshot) => {
                  console.log('querySnapshot =======> ', querySnapshot);
                  console.log(
                    'querySnapshot.size =======> ',
                    querySnapshot.size,
                  );
                  querySnapshot.forEach((doc) => {
                    arr.push({
                      ...doc.data(),
                      status: doc.data().status
                        ? doc.data().status
                        : 'invalidated',
                    });
                  });
                })
                .catch((error) => {
                  reject(error);
                });
            }

            if (
              pageIndex * pageSize + pageSize >
              appEasyState.docsCount.invalidated
            ) {
              const sharedOffset = 0;
              const sharedLimit = Math.min(
                appEasyState.docsCount.shared,
                pageSize - arr.length,
              );

              if (sharedOffset >= 0 && sharedLimit > 0) {
                const startAtDocShared = await sharedRef
                  .orderBy(orderBy, 'asc')
                  .limit(sharedOffset + 1)
                  .get()
                  .then((querySnapshot) => querySnapshot.docs[sharedOffset]);

                const startAtValueShared = startAtDocShared
                  ? startAtDocShared.data()[orderBy]
                  : null;

                if (startAtValueShared) {
                  await sharedRef
                    .orderBy(orderBy, 'asc')
                    .startAt(startAtValueShared)
                    .limit(sharedLimit)
                    .get()
                    .then(async (querySnapshot) => {
                      console.log('querySnapshot =======> ', querySnapshot);
                      console.log(
                        'querySnapshot.size =======> ',
                        querySnapshot.size,
                      );
                      querySnapshot.forEach((doc) => {
                        arr.push({
                          ...doc.data(),
                          status: doc.data().status
                            ? doc.data().status
                            : 'shared',
                        });
                      });
                    })
                    .catch((error) => {
                      reject(error);
                    });
                }
              }
            }
          }

          console.log('before sort all arr =======> ', arr);
          if (sortBy === 'desc') {
            console.log('in desc =======> ');
            arr.sort((a, b) =>
              typeof b[orderBy] === 'string'
                ? b[orderBy].localeCompare(a[orderBy])
                : b[orderBy] - a[orderBy],
            );
            console.log('sorted =======> ');
            console.log('after sort all arr =======> ', arr);
          }

          resolve(arr);
        } else {
          const invalidatedOffset = pageIndex * pageSize,
            invalidatedLimit =
              appEasyState.docsCount.invalidated - invalidatedOffset;

          if (invalidatedOffset >= 0 && invalidatedLimit > 0) {
            const startAtDocInvalidated = await invalidatedRef
              .orderBy(orderBy, 'asc')
              .limit(invalidatedOffset + 1)
              .get()
              .then((querySnapshot) => querySnapshot.docs[invalidatedOffset]);

            const startAtValueInvalidated = startAtDocInvalidated
              ? startAtDocInvalidated.data()[orderBy]
              : null;

            if (startAtValueInvalidated) {
              await invalidatedRef
                .orderBy(orderBy, 'asc')
                .startAt(startAtValueInvalidated)
                .limit(invalidatedLimit)
                .get()
                .then((querySnapshot) => {
                  console.log('querySnapshot =======> ', querySnapshot);
                  console.log(
                    'querySnapshot.size =======> ',
                    querySnapshot.size,
                  );
                  querySnapshot.forEach((doc) => {
                    arr.push({
                      ...doc.data(),
                      status: doc.data().status
                        ? doc.data().status
                        : 'invalidated',
                    });
                  });
                })
                .catch((error) => {
                  reject(error);
                });
            }
          }

          const offset =
            (pageIndex - 1) * pageSize -
            (appEasyState.docsCount.invalidated % pageSize);

          const sharedOffset = offset <= 0 ? 0 : offset,
            sharedLimit = Math.min(
              pageSize - arr.length,
              appEasyState.docsCount.shared - sharedOffset,
            );

          if (sharedOffset >= 0 && sharedLimit > 0) {
            const startAtDocShared = await sharedRef
              .orderBy(orderBy, 'asc')
              .limit(sharedOffset + 1)
              .get()
              .then((querySnapshot) => querySnapshot.docs[sharedOffset]);

            const startAtValueShared = startAtDocShared
              ? startAtDocShared.data()[orderBy]
              : null;

            if (startAtValueShared) {
              await sharedRef
                .orderBy(orderBy, 'asc')
                .startAt(startAtValueShared)
                .limit(sharedLimit)
                .get()
                .then((querySnapshot) => {
                  console.log('querySnapshot =======> ', querySnapshot);
                  console.log(
                    'querySnapshot.size =======> ',
                    querySnapshot.size,
                  );
                  querySnapshot.forEach((doc) => {
                    arr.push({
                      ...doc.data(),
                      status: doc.data().status ? doc.data().status : 'shared',
                    });
                  });
                })
                .catch((error) => {
                  reject(error);
                });
            }
          }

          console.log('before sort all arr =======> ', arr);
          if (sortBy === 'desc') {
            console.log('in desc =======> ');
            arr.sort((a, b) =>
              typeof b[orderBy] === 'string'
                ? b[orderBy].localeCompare(a[orderBy])
                : b[orderBy] - a[orderBy],
            );
            console.log('sorted =======> ');
            console.log('after sort all arr =======> ', arr);
          }

          resolve(arr);
        }
      });
    } else if (docType === 'pending') {
      return new Promise(async (resolve, reject) => {
        console.log(
          'getDocs pending pageIndex * pageSize + 1 =======> ',
          pageIndex * pageSize + 1,
        );

        // this.firestore
        //   .collection('users')
        //   .doc(user.uid)
        //   .collection('documents')
        //   .orderBy('projectNumber', 'asc')
        //   .limit(pageIndex * pageSize + 1)
        //   .get()
        //   .then((querySnapshot) => {
        //     const arr = [];
        //     querySnapshot.forEach((doc) => {
        //       arr.push({
        //         ...doc.data(),
        //         status: doc.data().status ? doc.data().status : 'pending',
        //       });
        //
        //     });
        //     console.log('getDocs arr', arr)
        //     resolve(arr)
        //     return
        //   }).catch((error) => {
        //     console.log('getDocs error', error)
        //     reject(error);
        //   });
        // resolve([])

        const startAtDoc = await this.firestore
          .collection('users')
          .doc(user.uid)
          .collection('documents')
          .orderBy(orderBy, 'asc')
          .limit(pageIndex * pageSize + 1)
          .get()
          .then((querySnapshot) => querySnapshot.docs[pageIndex * pageSize]);

        console.log('getDocs startAtDoc', startAtDoc);

        const startAtValue = startAtDoc ? startAtDoc.data()[orderBy] : null;

        console.log('getDocs pending startAtValue', startAtValue);

        documentsRef
          .orderBy(orderBy, 'asc')
          .startAt(startAtValue)
          .limit(pageSize)
          .get()
          .then((querySnapshot) => {
            console.log('getDocs querySnapshot =======> ', querySnapshot);
            let arr = [];
            querySnapshot.forEach((doc) => {
              arr.push({
                ...doc.data(),
                status: doc.data().status ? doc.data().status : 'pending',
              });
            });
            console.log('getDocs pending arr.length', arr.length);
            if (sortBy === 'desc') {
              console.log('getDocs in desc =======> ');
              arr.sort((a, b) =>
                typeof b[orderBy] === 'string'
                  ? b[orderBy].localeCompare(a[orderBy])
                  : b[orderBy] - a[orderBy],
              );
              console.log('getDocs sorted =======> ');
              console.log('getDocs after sort arr =======> ', arr);
            }
            console.log('getDocs pending arr =======> ', arr);
            resolve(arr);
          })
          .catch((error) => {
            reject(error);
          });
      });
    } else if (docType === 'pacted') {
      return new Promise(async (resolve, reject) => {
        console.log(
          'pageIndex * pageSize + 1 =======> ',
          pageIndex * pageSize + 1,
        );

        const startAfterDoc = await sealPactedRef
          .orderBy(orderBy, 'asc')
          .limit(pageIndex * pageSize + 1)
          .get()
          .then((querySnapshot) => querySnapshot.docs[pageIndex * pageSize]);

        const startAfterValue = startAfterDoc
          ? startAfterDoc.data()[orderBy]
          : null;

        sealPactedRef
          .orderBy(orderBy, 'asc')
          .startAt(startAfterValue)
          .limit(pageSize)
          .get()
          .then((querySnapshot) => {
            console.log('querySnapshot =======> ', querySnapshot);
            let arr = [];
            querySnapshot.forEach((doc) => {
              if (doc.data().sealPactDownloadUrl) {
                arr.push({ ...doc.data(), status: 'pacted' });
              }
            });
            if (sortBy === 'desc') {
              console.log('in desc =======> ');
              arr.sort((a, b) =>
                typeof b[orderBy] === 'string'
                  ? b[orderBy].localeCompare(a[orderBy])
                  : b[orderBy] - a[orderBy],
              );
              console.log('sorted =======> ');
              console.log('after sort arr =======> ', arr);
            }
            console.log('pacted arr =======> ', arr);
            resolve(arr);
          })
          .catch((error) => {
            reject(error);
          });
      });
    } else if (docType === 'invalidated') {
      return new Promise(async (resolve, reject) => {
        console.log(
          'pageIndex * pageSize + 1 =======> ',
          pageIndex * pageSize + 1,
        );

        const startAfterDoc = await invalidatedRef
          .orderBy(orderBy, 'asc')
          .limit(pageIndex * pageSize + 1)
          .get()
          .then((querySnapshot) => querySnapshot.docs[pageIndex * pageSize]);

        const startAfterValue = startAfterDoc
          ? startAfterDoc.data()[orderBy]
          : null;

        invalidatedRef
          .orderBy(orderBy, 'asc')
          .startAt(startAfterValue)
          .limit(pageSize)
          .get()
          .then((querySnapshot) => {
            console.log('querySnapshot =======> ', querySnapshot);
            let arr = [];
            querySnapshot.forEach((doc) => {
              if (doc.data().sealPactDownloadUrl) {
                arr.push({ ...doc.data(), status: 'invalidated' });
              }
            });
            if (sortBy === 'desc') {
              console.log('in desc =======> ');
              arr.sort((a, b) =>
                typeof b[orderBy] === 'string'
                  ? b[orderBy].localeCompare(a[orderBy])
                  : b[orderBy] - a[orderBy],
              );
              console.log('sorted =======> ');
              console.log('after sort arr =======> ', arr);
            }
            resolve(arr);
          })
          .catch((error) => {
            reject(error);
          });
      });
    } else if (docType === 'shared') {
      return new Promise(async (resolve, reject) => {
        console.log(
          'pageIndex * pageSize + 1 =======> ',
          pageIndex * pageSize + 1,
        );

        const startAfterDoc = await sharedRef
          .orderBy(orderBy, 'asc')
          .limit(pageIndex * pageSize + 1)
          .get()
          .then((querySnapshot) => querySnapshot.docs[pageIndex * pageSize]);

        const startAfterValue = startAfterDoc
          ? startAfterDoc.data()[orderBy]
          : null;

        let query = sharedRef.orderBy(orderBy, 'asc');

        query
          .startAt(startAfterValue)
          .limit(pageSize)
          .get()
          .then((querySnapshot) => {
            console.log('querySnapshot =======> ', querySnapshot);
            let arr = [];
            querySnapshot.forEach((doc) => {
              arr.push({
                ...doc.data(),
                status: doc.data().status ? doc.data().status : 'shared',
              });
            });
            if (sortBy === 'desc') {
              console.log('in desc =======> ');
              arr.sort((a, b) =>
                typeof b[orderBy] === 'string'
                  ? b[orderBy].localeCompare(a[orderBy])
                  : b[orderBy] - a[orderBy],
              );
              console.log('sorted =======> ');
              console.log('after sort all arr =======> ', arr);
            }
            console.log('arr =======> ', arr);
            resolve(arr);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  };

  getUserPhoneNumberAgainstEmail = (email) => {
    return new Promise((resolve, reject) => {
      const userDataDB = this.firestore.collection('users');
      userDataDB
        .where('email', '==', email)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((userData) => {
            userData.ref
              .collection('seals')
              .where('state', '==', 'Florida')
              .get()
              .then((sealsQuerySnapshot) => {
                return resolve({
                  phoneNumber: userData.data().phoneNumber,
                  twoFactorAuthentication:
                    userData.data().twoFactorAuthentication,
                  sealsState: sealsQuerySnapshot.size > 0 ? true : false,
                });
              })
              .catch((error) => {
                return reject(error);
              });
          });
        });
    });
  };
  getAllUsers = () => {
    return new Promise((resolve, reject) => {
      this.firestore
        .collection('users')
        .get()
        .then((querySnapshot) => {
          const userList = [];
          querySnapshot.forEach((userData) => {
            userList.push(userData.data());
          });
          resolve(userList);
          return;
        });
    });
  };

  getAllPendingDocuments = (userId) => {
    return new Promise((resolve, reject) => {
      this.firestore
        .collection('users')
        .doc(userId)
        .collection('documents')
        .orderBy('dateUploaded', 'desc')
        .get()
        .then((querySnapshot) => {
          const arr = [];
          querySnapshot.forEach((doc) => {
            if (!doc.data().sealPacterType) {
              arr.push({
                ...doc.data(),
                sealPacterType: 'viewer',
              });
            } else {
              arr.push({ ...doc.data() });
            }
          });
          console.log('getAllPendingDocuments arr', arr);
          resolve(arr);
        });
      // this.firestore
      //   .collection('users')
      //   .doc(userId)
      //   .collection('shared')
      //   .get()
      //   .then((querySnapshot) => {
      //     const arr = [];
      //     let id = 1;
      //     querySnapshot.forEach((doc) => {
      //       if (!doc.data().sealPacterType) {
      //         arr.push({ ...doc.data(), sealPacterType: 'viewer' });
      //       } else {
      //         arr.push({ ...doc.data() });
      //       }
      //     });
      //     this.firestore
      //       .collection('users')
      //       .doc(userId)
      //       .collection('documents')
      //       .get()
      //       .then((querySnapshot) => {
      //         querySnapshot.forEach((doc) => {
      //           if (!doc.data().sealPacterType) {
      //             arr.push({
      //               ...doc.data(),
      //               sealPacterType: 'viewer',
      //             });
      //           } else {
      //             arr.push({ ...doc.data() });
      //           }
      //         });
      //         resolve(arr);
      //       });
      //   });
    });
  };

  getDocumentData = (uid) => {
    const filePath = '/' + uid + '/documents/testing';
    return this.storage.ref().child(filePath).getDownloadURL();
  };

  createInvitations = (uid, payload) => {
    return new Promise(async (resolve, reject) => {
      const { emails } = payload;
      let newEmails = emails;

      for (let email of emails) {
        await this.firestore
          .collection('users')
          .where('email', '==', email)
          .get()
          .then(async (querySnapshotUsers) => {
            if (querySnapshotUsers.size === 0) {
              await this.firestore
                .collection('users')
                .doc(uid)
                .collection('linkInvitation')
                .where('email', '==', email)
                .get()
                .then((querySnapshot) => {
                  if (querySnapshot.size === 0) {
                    const currentDate = new Date();
                    const unixTimestamp = Math.floor(
                      currentDate.getTime() / 1000,
                    );

                    const data = {
                      email,
                      status: 'Pending',
                      hidden: false,
                      createdAt: unixTimestamp,
                      updatedAt: unixTimestamp,
                    };

                    this.firestore
                      .collection('users')
                      .doc(uid)
                      .collection('linkInvitation')
                      .doc()
                      .set(data)
                      .then((dataRes) => {
                        // return resolve(dataRes);
                      })
                      .catch((error) => {
                        console.log('error ===> ', error);
                        reject(error);
                        return;
                      });
                  } else {
                    console.log('in else');
                    newEmails = newEmails.filter((item) => item !== email);
                  }
                })
                .catch((error) => {
                  reject(error);
                  return;
                });
            } else {
              newEmails = newEmails.filter((item) => item !== email);
            }
          })
          .catch((error) => {});
      }

      console.log('newEmails', newEmails);

      if (newEmails.length === 0) {
        return reject({
          success: false,
          message: 'Implementation not performed',
        });
      }

      return resolve({
        success: true,
        message: 'Implementation performed',
        data: newEmails,
      });
    }).catch((error) => {
      console.log('promise error ===> ', error);
    });
  };

  getInvitations = (uid) => {
    return new Promise(async (resolve, reject) => {
      await this.firestore
        .collection('users')
        .doc(uid)
        .collection('linkInvitation')
        .where('hidden', '==', false)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.size === 0) {
            resolve([]);
            return;
          }

          const arr = [];
          querySnapshot.forEach((doc) => {
            arr.push(doc.data());
          });
          if (arr && arr.length > 0) {
            arr.sort((a, b) => b['createdAt'] - a['createdAt']);
          }
          resolve(arr);
          return;
        })
        .catch((error) => {
          reject(error);
          return;
        });
    }).catch((error) => {
      console.log('promise error ===> ', error);
    });
  };

  updateInvitation = (uid, data) => {
    return new Promise(async (resolve, reject) => {
      const { email, status } = data;
      console.log('updateInvitation data', data);
      await this.firestore
        .collection('users')
        .doc(uid)
        .collection('linkInvitation')
        .where('email', '==', email)
        .get()
        .then(async (querySnapshot) => {
          const currentDate = new Date();
          const unixTimestamp = Math.floor(currentDate.getTime() / 1000);

          if (querySnapshot.size === 0) {
            await this.firestore
              .collection('users')
              .where('email', '==', email)
              .get()
              .then(async (querySnapshotUsers) => {
                if (querySnapshotUsers.size === 0) {
                  const newDocData = {
                    ...data,
                    status: 'Pending',
                    hidden: false,
                    createdAt: unixTimestamp,
                    updatedAt: unixTimestamp,
                  };

                  return this.firestore
                    .collection('users')
                    .doc(uid)
                    .collection('linkInvitation')
                    .add(newDocData)
                    .then((docRef) => {
                      resolve(docRef); // Resolve with the newly created document reference
                    })
                    .catch((error) => {
                      reject(error);
                    });
                } else {
                  reject({ message: 'User already exists' });
                }
              })
              .catch((err) => {});
          } else {
            querySnapshot.forEach((document) => {
              console.log('document.data()', document.data());
              document.ref
                .update({
                  ...data,
                  updatedAt: unixTimestamp,
                })
                .then((updatedDocumentRes) => {

                  resolve(updatedDocumentRes);
                  return;
                })
                .catch((error) => {
                  reject(error);
                  return;
                });
            });
          }
        })
        .catch((error) => {
          reject(error);
          return;
        });
    }).catch((error) => {
      console.log('promise error ===> ', error);
    });
  };

  getConstants = () => {
    return new Promise(async (resolve, reject) => {
      await this.firestore
        .collection('constants')
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.size === 0) {
            resolve({});
            return;
          }

          console.log('getConstants querySnapshot.size', querySnapshot.size);

          querySnapshot.forEach((doc) => {
            resolve(doc.data());
          });
          return;
        })
        .catch((error) => {
          reject(error);
          return;
        });
    }).catch((error) => {
      console.log('getConstants promise error ===> ', error);
    });
  };

  hideInvitation = (uid, email) => {
    return new Promise(async (resolve, reject) => {
      await this.firestore
        .collection('users')
        .doc(uid)
        .collection('linkInvitation')
        .where('email', '==', email)
        .get()
        .then(async (querySnapshot) => {
          if (querySnapshot.size !== 0) {
            querySnapshot.forEach((document) => {
              // console.log('document.data()', document.data())
              document.ref
                .update({
                  hidden: true,
                })
                .then((updatedDocumentRes) => {

                  resolve(updatedDocumentRes);
                  return;
                })
                .catch((error) => {
                  reject(error);
                  return;
                });
            });
          }
        })
        .catch((error) => {
          reject(error);
          return;
        });
    }).catch((error) => {
      console.log('promise error ===> ', error);
    });
  };

  getHiddenInvitations = (uid) => {
    return new Promise(async (resolve, reject) => {
      await this.firestore
        .collection('users')
        .doc(uid)
        .collection('linkInvitation')
        .where('hidden', '==', true)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.size === 0) {
            resolve([]);
            return;
          }

          const arr = [];
          querySnapshot.forEach((doc) => {
            arr.push(doc.data());
          });
          if (arr && arr.length > 0) {
            arr.sort((a, b) => b['createdAt'] - a['createdAt']);
          }
          resolve(arr);
          return;
        })
        .catch((error) => {
          reject(error);
          return;
        });
    }).catch((error) => {
      console.log('promise error ===> ', error);
    });
  };

  unhideInvitation = (uid, email) => {
    return new Promise(async (resolve, reject) => {
      await this.firestore
        .collection('users')
        .doc(uid)
        .collection('linkInvitation')
        .where('email', '==', email)
        .get()
        .then(async (querySnapshot) => {
          if (querySnapshot.size !== 0) {
            querySnapshot.forEach((document) => {
              // console.log('document.data()', document.data())
              document.ref
                .update({
                  hidden: false,
                })
                .then((updatedDocumentRes) => {

                  resolve(updatedDocumentRes);
                  return;
                })
                .catch((error) => {
                  reject(error);
                  return;
                });
            });
          }
        })
        .catch((error) => {
          reject(error);
          return;
        });
    }).catch((error) => {
      console.log('promise error ===> ', error);
    });
  };
  getFirmsList = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const querySnapshot = await this.firestore
          .collection('users')
          .where('firmName', '>', '')
          .get();

        if (querySnapshot.empty) {
          resolve([]);
          return;
        }

        const firmsMap = {};

        const promises = querySnapshot.docs.map(async (doc) => {
          const userData = doc.data();
          const userId = doc.id;
          if (userId && userData.firmName) {
            if (!firmsMap[userData.firmName]) {
              firmsMap[userData.firmName] = {
                usersCount: 0,
                documentsCount: 0,
              };
            }
            firmsMap[userData.firmName].usersCount++;
            try {
              const documentsSnapshot = await this.firestore
                .collection('users')
                .doc(userId)
                .collection('documents')
                .where('firmName', '==', userData.firmName)
                .get();
              if (!documentsSnapshot.empty) {
                firmsMap[userData.firmName].documentsCount +=
                  documentsSnapshot.size;
              }
            } catch (documentError) {
              console.warn(
                `No documents found for user ${userId}: `,
                documentError,
              );
            }
          }
        });
        await Promise.all(promises);
        const data = Object.keys(firmsMap).map((firmName) => ({
          firmName,
          yearlyGoalCompletion: '75%',
          designProfessionals: firmsMap[firmName].usersCount,
          documentsCount: firmsMap[firmName].documentsCount,
        }));

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export default Firebase;

// update nested docs like this
// this.firestore
// .collection("users")
// .doc(id)
// .collection("articles")
// .doc("XfUrq72oZsNAwPW3WDoB")
// .set(newValue);
