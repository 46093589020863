/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import Header from '../../Header';
import CreateNewSeal from './CreateNewSeal';
import CreateNewSignature from './CreateNewSignature';
import s from './style.scss';
import { Tabs, Tab } from 'react-bootstrap';
import ShowAssets from './ShowAllSeals';
import withAuthorization from '../../../auth/session/withAuthorization';
import Spinner from '../../Layout/Spinner';
import moment from 'moment';
import { view } from 'react-easy-state';
import { appEasyState } from '../../../easyStateStore';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS, NOTIFICATION_PROPS_LONGER } from '../../../utils/constants';

const MySeals = ({ user, firebase }) => {
  const [key, setkey] = useState(1);
  const [allAssets, setAllAssets] = useState([]);
  const [seals, setSeals] = useState([]);
  const [signatures, setSignatures] = useState([]);
  const [submittalReviewStamps, setSubmittalReviewStamps] = useState([]);

  //Commenting due to change in use-case

  // const [activeSeals, setActiveSeals] = useState([]);
  // const [inactiveSeals, setInActiveSeals] = useState([]);
  // const [activeSignatures, setActiveSignatures] = useState([]);
  // const [inactiveSignatures, setInActiveSignatures] = useState([]);

  const [states, setStates] = useState([]);
  // const [rand, setRandom] = useState();
  const [loading, setLoading] = useState(true);
  const [refreshSeals, setRefreshSeals] = useState(true);

  useEffect(() => {
    if (user.uid.localeCompare(appEasyState.userId) === 0 && appEasyState.showOnboardingCompletionMessage === 'true') {
      store.addNotification({
        title: 'Profile Updated Successfully!',
        message: 'Please add a Seal, Submittal Review Stamp or Signature (depending on your needs) to get started!',
        type: 'default',
        ...NOTIFICATION_PROPS_LONGER,
      });
      appEasyState.showOnboardingCompletionMessage = 'false'
      localStorage.setItem('showOnboardingCompletionMessage', 'false')
    }
  }, [])

  const getSealsAndSigns = () => {
    // setLoading(true);
    firebase
      .getStates()
      .then((states) => {
        setStates(states);
        // return states;
      })
      .catch((error) => {
        console.log('error ======> ', error);
        setLoading(false);
      });

    const sealsAndSignature = [];
    console.log('user', user);
    firebase
      .getSeals(user.uid)
      .then((seals) => {
        firebase.getStamps(user.uid).then((stamps) => {
          firebase
            .getSignatures(user.uid)
            .then((signatures) => {
              for (let stamp of stamps) {
                if (stamp.isActive !== undefined)
                  stamp['status'] = stamp.isActive
                else stamp['status'] = true
              }
              setLoading(false);
              setSignatures(signatures);
              setSeals(seals);
              setSubmittalReviewStamps(stamps);
              console.log('signaturessss ===> ', signatures);
              const sortedSeals = seals.sort((a, b) => a.state.localeCompare(b.state))
              sealsAndSignature.push(...sortedSeals, ...signatures, ...stamps);
              // sealsAndSignature.sort((a, b) => b.createdAt - a.createdAt);
              // sealsAndSignature.sort(function (a, b) {
              //   var textA = a.state;
              //   var textB = b.state;
              //   return textA < textB ? -1 : textA > textB ? 1 : 0;
              // });

              console.log('sealsAndSignature ===> ', sealsAndSignature);
              setAllAssets(sealsAndSignature);

              //commenting due to use-case change

              const tempActiveSeals = [];
              const tempInactiveSeals = [];

              // filter active and inactive seals
              seals.forEach((seal) => {
                // ? (seal.status = !seal.status)
                // : (seal.status = seal.status);
                if (
                  moment(seal.expirationDate).isBefore(
                    moment(new Date()),
                    'day',
                  )
                ) {
                  seal.status = false;
                }
                seal.status === true
                  ? tempActiveSeals.push(seal)
                  : tempInactiveSeals.push(seal);
              });
              console.log('tempActiveSeals ==> ', tempActiveSeals);
              tempActiveSeals.sort(function (a, b) {
                var textA = a.state;
                var textB = b.state;
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              });
              tempInactiveSeals.sort(function (a, b) {
                var textA = a.state;
                var textB = b.state;
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              });

              // setActiveSeals(tempActiveSeals);
              // setInActiveSeals(tempInactiveSeals);

              // filter active and inactive signatures

              //commenting due to use-case change

              const tempActiveSignatures = [];
              const tempInactiveSignatures = [];
              signatures.forEach((sign) =>
                sign.status === true
                  ? tempActiveSignatures.push(sign)
                  : tempInactiveSignatures.push(sign),
              );
              tempActiveSignatures.sort(
                (a, b) => b.createdAt - a.createdAt,
              );
              tempInactiveSignatures.sort(
                (a, b) => b.createdAt - a.createdAt,
              );
              // setActiveSignatures(tempActiveSignatures);
              // setInActiveSignatures(tempInactiveSignatures);
            })
            .catch((error) => {
              console.log('error in getting seals and signs=== > ', error);
              setLoading(false);
            });
        });
      })
      .catch((error) => {
        console.log('error ======> ', error);
        setLoading(false);
      });
  };

  const showSpinner = () => {
    setLoading(true);
  };

  const stopSpinner = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (refreshSeals) {
      getSealsAndSigns();
      setRefreshSeals(false)
      stopSpinner()
    }
  }, [refreshSeals]);

  const getSelectedItemId = (selectedItem, isSealInfo) => {
    let index;
    if (selectedItem.type === 'stamp') {
      index = allAssets.findIndex((obj) => obj.id === selectedItem['id']);
    } else {
      index = allAssets.findIndex((obj) => obj.id === selectedItem['id']);
    }
    if (!isSealInfo) {
      allAssets[index].status = !selectedItem['status'];
    } else if (isSealInfo) {
      const sealStatus = moment(selectedItem.expirationDate).isSameOrAfter(
        moment(new Date()),
        'day',
      )
        ? true
        : false;
      allAssets[index].status = sealStatus;
    }
    allAssets[index].expirationDate = selectedItem['expirationDate'];
    setAllAssets(allAssets);
    setSeals(allAssets.filter((asset) => asset.type === 'stamp'));
    // setActiveSeals(
    //   allAssets.filter((asset) => asset.type === 'stamp' && asset.status),
    // );
    // setInActiveSeals(
    //   allAssets.filter((asset) => asset.type === 'stamp' && !asset.status),
    // );
    setSignatures(allAssets.filter((asset) => asset.type === 'signature'));
    setSubmittalReviewStamps(
      allAssets.filter((asset) => asset.type === 'submittalReviewStamp'),
    );
    // setActiveSignatures(
    //   allAssets.filter((asset) => asset.type !== 'stamp' && asset.status),
    // );
    // setInActiveSignatures(
    //   allAssets.filter((asset) => asset.type !== 'stamp' && !asset.status),
    // );
  };

  const getselectedSealInfo = (item) => {
    console.log('item ', item);
  };
  const removeSeletedItem = (item) => {
    setAllAssets(allAssets.filter((el) => el.id !== item.id));
    if (item.type === 'stamp') {
      setSeals(seals.filter((el) => el.id !== item.id));
    } else if (item.type === 'signature') {
      setSignatures(signatures.filter((el) => el.id !== item.id));
    } else if (item.type === 'submittalReviewStamp') {
      setSubmittalReviewStamps(
        submittalReviewStamps.filter((el) => el.id !== item.id),
      );
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <Header title="My Seals" />
      <div className={s.root}>
        <CreateNewSeal
          user={user}
          firebase={firebase}
          getSealsAndSigns={getSealsAndSigns}
          showSpinner={showSpinner}
          isStamp={false}
          setRefreshSeals={setRefreshSeals}
        />
        <CreateNewSignature
          user={user}
          firebase={firebase}
          getSealsAndSigns={getSealsAndSigns}
          setRefreshSeals={setRefreshSeals}
        />
        <CreateNewSeal
          user={user}
          firebase={firebase}
          getSealsAndSigns={getSealsAndSigns}
          showSpinner={showSpinner}
          isStamp={true}
          setRefreshSeals={setRefreshSeals}
        />
        <Tabs
          id="docs-tab"
          activeKey={key}
          onSelect={(id) => setkey(id)}
          className={`docstable ${s.tabs}`}
        >
          <Tab eventKey={1} title="All" className="docsTab">
            <ShowAssets
              removeSeletedItem={removeSeletedItem}
              assetArray={allAssets}
              getSelectedItemId={getSelectedItemId}
              getselectedSealInfo={getselectedSealInfo}
              arrayType="allAssets"
              states={states}
              user={user}
              firebase={firebase}
              showSpinner={showSpinner}
              stopSpinner={stopSpinner}
              setRefreshSeals={setRefreshSeals}
            />
          </Tab>
          <Tab eventKey={2} title="Seals" className="docsTab">
            <ShowAssets
              removeSeletedItem={removeSeletedItem}
              assetArray={seals.sort((a, b) => a.state.localeCompare(b.state))}
              getSelectedItemId={getSelectedItemId}
              arrayType="seals"
              states={states}
              user={user}
              firebase={firebase}
              showSpinner={showSpinner}
              stopSpinner={stopSpinner}
              setRefreshSeals={setRefreshSeals}
            />
          </Tab>
          <Tab eventKey={3} title="Signatures" className="docsTab">
            <ShowAssets
              removeSeletedItem={removeSeletedItem}
              assetArray={signatures}
              getSelectedItemId={getSelectedItemId}
              arrayType="signatures"
              user={user}
              firebase={firebase}
              showSpinner={showSpinner}
              stopSpinner={stopSpinner}
              setRefreshSeals={setRefreshSeals}
            />
          </Tab>
          <Tab eventKey={4} title="Stamps" className="docsTab">
            <ShowAssets
              removeSeletedItem={removeSeletedItem}
              assetArray={submittalReviewStamps}
              getSelectedItemId={getSelectedItemId}
              arrayType="submittalReviewStamps"
              user={user}
              firebase={firebase}
              showSpinner={showSpinner}
              stopSpinner={stopSpinner}
              setRefreshSeals={setRefreshSeals}
            />
          </Tab>
          {/* <Tab eventKey={4} title="Active seals" className="docsTab">
            <ShowAssets
              assetArray={activeSeals}
              getSelectedItemId={getSelectedItemId}
              arrayType="activeSeals"
              states={states}
              user={user}
              firebase={firebase}
              showSpinner={showSpinner}
              stopSpinner={stopSpinner}
            />
          </Tab>
          <Tab eventKey={5} title="Inactive seals" className="docsTab">
            <ShowAssets
              assetArray={inactiveSeals}
              getSelectedItemId={getSelectedItemId}
              states={states}
              arrayType="inactiveSeals"
              user={user}
              firebase={firebase}
              showSpinner={showSpinner}
              stopSpinner={stopSpinner}
            />
          </Tab>
          <Tab eventKey={6} title="Active signatures" className="docsTab">
            <ShowAssets
              assetArray={activeSignatures}
              getSelectedItemId={getSelectedItemId}
              arrayType="activeSignatures"
              states={states}
              user={user}
              firebase={firebase}
              showSpinner={showSpinner}
              stopSpinner={stopSpinner}
            />
          </Tab>
          <Tab eventKey={7} title="Inactive signatures" className="docsTab">
            <ShowAssets
              assetArray={inactiveSignatures}
              getSelectedItemId={getSelectedItemId}
              states={states}
              arrayType="inactiveSignatures"
              user={user}
              firebase={firebase}
              showSpinner={showSpinner}
              stopSpinner={stopSpinner}
            />
          </Tab> */}
        </Tabs>
      </div>
    </Fragment>
  );
};
const condition = (user) => !!user;
export default withAuthorization(condition)(view(MySeals));
