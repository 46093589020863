import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Layout from '../Components/Layout';
import ForgotPassword from '../Components/Pages/ForgotPassword';
import Welcome from '../Components/Pages/Welcome';
import LoginAndVerification from '../Components/Pages/LoginAndVerification';
import EmailVerification from '../Components/Pages/EmailVerification';
import DocumentDetails from '../Components/Pages/DocumentDetails';
import MyDashboard from '../Components/Pages/MyDashboard';
import MySeals from '../Components/Pages/MySeals';
import MyAccount from '../Components/Pages/MyAccount';
import MyDocuments from '../Components/Pages/MyDocuments';
import SealPactDetailScreen from '../Components/Pages/SealPactDetails/SealPactDetailsScreen';
import SealPactedDetailScreen from '../Components/Pages/SealPactedDetails/SealPactedDetailsScreen';
import DocumentPreview from '../Components/Pages/UploadDocument/DocumentPreview';
import Pricing from '../Components/Pages/Pricing';
import Subscription from '../Components/Pages/Subscription';
import Unsubscribe from '../Components/Pages/Unsubscribe';
import AllUsers from '../Components/Pages/AllUsers';
import UserDocs from '../Components/Pages/UserDocs';
import ScannedDocumentDetails from '../Components/Pages/ScannedDocumentDetails';
import Onboarding from '../Components/Pages/Onboarding';
import SubscriptionPortal from '../Components/Pages/SubscriptionPortal';
import Completion from '../Components/Pages/SubscriptionPortal/Completion';
import Referrals from '../Components/Pages/Referrals';
import EdgePortalLogin from '../Components/Pages/EdgePortal/LoginAndVerification';
import FirmDetails from '../Components/Pages/EdgePortal/FirmDetails';
import FirmAnalytics from '../Components/Pages/EdgePortal/FirmAnalytics';
import Statistics from '../Components/Pages/EdgePortal/Statistics';
import DesignProfessionals from '../Components/Pages/EdgePortal/DesignProfessionals';

const Routes = (props) => {
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const checkAuth = () => {
    const userId= getCookie('userId')
    const localUserId = localStorage.getItem('userId') || "null"
    console.log('checkAuth 2 is called:', localUserId !=="null"|| userId?true:false )
    return localUserId !=="null"|| userId?true:false
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Welcome} />
        <Route path="/signup" component={Onboarding} />
        <Route path="/login" component={LoginAndVerification} />
        <Route path="/pricing" component={Pricing} />
        <Route path="/forgotPassword/:oobCode?" component={ForgotPassword} />
        <Route path="/sealpactedDetails" component={SealPactedDetailScreen} />
        <Route path="/unSubscribe" component={Unsubscribe} />
        <Route path="/firmDetails" component={FirmDetails} />
        <Route path="/statistics" component={Statistics} />
        <Route path="/firmAnalytics" component={FirmAnalytics} />
        <Route path="/designProfessionals" component={DesignProfessionals} />

        {checkAuth() && (
          <Route path="/emailVerification" component={EmailVerification} />
        )}

        {checkAuth() ? (
          <Layout>
            <Route path="/sealpactDetails" component={SealPactDetailScreen} />
            <Route path="/documentDetails" component={DocumentDetails} />
            <Route path="/documentPreview" component={DocumentPreview} />
            <Route path="/dashboard" component={MyDashboard} />
            <Route path="/seals" component={MySeals} />
            <Route path="/account" component={MyAccount} />
            <Route path="/documents" component={MyDocuments} />
            {/*<Route path="/subscription" component={Subscription} />*/}
            <Route path="/allUsers" component={AllUsers} />
            <Route path="/userDocs" component={UserDocs} />
            <Route
              path="/scannedDocumentDetails"
              component={ScannedDocumentDetails}
            />
            <Route path="/subscription" component={SubscriptionPortal} />
            <Route path="/completion" component={Completion} />
            <Route path="/referrals" component={Referrals} />
          </Layout>
        ) : (
          <Redirect to='/login'/>
        )}
      </Switch>
    </BrowserRouter>
  )
};

export default Routes;
