import React, { useEffect } from 'react';
import LogoBrand from '../../LogoBrand';
import s from './style.scss';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../../../auth/firebase';
import querystring from 'query-string';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS } from '../../../utils/constants';

const Welcome = (props) => {
  const {
    history: { push },
  } = props;
  const redirect = (url) => {
    url === 'signup' ? push('/signup') : push('/login');
  };
  if (props.firebase.auth.currentUser) {
    push('dashboard');
  }
  const { mode } = querystring.parse(props.location.search);
  const { oobCode } = querystring.parse(props.location.search);

  if (mode === 'resetPassword') {
    push('/forgotPassword/?oobCode=' + oobCode);
  } else if (mode === 'verifyEmail') {
    props.firebase
      .doUpdateVerifyEmail(oobCode)
      .then((res) => {
        store.addNotification({
          title: 'Email verified',
          message: 'Email verified successfully.',
          type: 'default',
          ...NOTIFICATION_PROPS,
        });
        push('/login');
      })
      .catch((error) => {
        store.addNotification({
          title: 'Error',
          message: 'Link expired or broken.',
          type: 'danger',
          ...NOTIFICATION_PROPS,
        });
      });
  }

  const openUrl = () => {
    window.open('https://www.sealpact.com', '_self');
  };

  useEffect(() => {

    const handleTermination = async (event) => {
      await props.firebase.doSignOut().then(() => {
        console.log('logout 13 called')
        localStorage.setItem('encryptedString', 'null')
        console.log('back clicked')
      }).catch((err) => {
        console.log('handleTermination err', err);
      })
    }

    window.addEventListener('unload', handleTermination);
  }, [])

  return (
    <Container>
      <div className={s.root}>
        <Row>
          <Col
            xs={{ span: 10, offset: 1 }}
            md={{ span: 8, offset: 2 }}
            lg={{ span: 6, offset: 3 }}
            xl={{ span: 6, offset: 3 }}
          >
            {/* <p className={s.title}>Welcome to</p> */}
            <div className={s.pointer} onClick={() => openUrl()}>
              <LogoBrand imgSize={80} className={s.logoStyle} {...props} />
              <div className={s.motivation}>
                <p>Simple</p>
                <p>Secure</p>
                <p>Transparent</p>
              </div>
            </div>
            <div className={s.centerDiv}>
              <Button
                variant="primary"
                block
                onClick={() => redirect('signup')}
              >
                Sign up
              </Button>
              <Button
                variant="outline-primary"
                onClick={() => redirect('login')}
                block
              >
                Login
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default withRouter(withFirebase(Welcome));
